import {throttle} from "@foundation/helpers/throttle";
import LibraryManager from "./utils/LibraryManager";
import ConfigManager from "./utils/ConfigManager";
import exportFoundation from "./utils/exportFoundation";
import run from "./run";
import "normalize.css";

let winHeight = 0;
let winWidth = 0;
const updateCssVariables = ({deltaWinHeight = 100, deltaWinWidth = 100}) => {
    const doc = document.documentElement;
    const height = window.innerHeight;
    const width = window.innerWidth;
    // To avoid take into account resize due to android navbar, we avoid resize less 100px
    if (Math.abs(height - winHeight) > deltaWinHeight) {
        doc.style.setProperty("--win-height", `${height}px`);
        winHeight = height;
    }
    if (Math.abs(width - winWidth) > deltaWinWidth) {
        doc.style.setProperty("--win-width", `${width}px`);
        winWidth = width;
    }
    // store dimensions directly in window object for a quick access
    window.winHeight = height;
    window.winWidth = width;
};

const onResizeThrottled = throttle(updateCssVariables, 100);

// decorator to decide when if the function in argument must run directly or wait for an event to start
function loadOnEvent(func) {
    return function loadOnEventDecorator(...args) {
        if (window.cordova) {
            document.addEventListener("deviceready", () => func(...args), false);
        } else {
            func(...args);
        }
    };
}

// composition root
async function composer(options) {
    // create config loader
    const configManager = new ConfigManager(options && options.configID);

    // load settings
    const settings = await configManager.loadConfig("settings.json");
    const settingsOverride = await configManager.loadConfig("settings.override.json");

    // set application Layout ASAP to display the good booting screen
    document.title = settingsOverride.applicationName || settings.applicationName;

    // export shared symbols to be imported by applications
    exportFoundation("foundation");

    // load libraries
    const LIBRARIES = (await configManager.loadConfig("libraries.json")).libraries;

    // create library manager
    const themeOption = options && options.settings && options.settings.find(option => option[0] === "global.theme");
    const themeOverride = settingsOverride && settingsOverride.global && settingsOverride.global.theme;
    const theme = (themeOption && themeOption[1]) || themeOverride || settings.global.theme;
    const assetsOption = options && options.settings && options.settings.find(option => option[0] === "global.assets");
    const assetsOverride = settingsOverride && settingsOverride.global && settingsOverride.global.assets;
    const assets = (assetsOption && assetsOption[1]) || assetsOverride || settings.global.assets;
    const libraryManager = new LibraryManager({theme, assets});

    // register libraries
    for (const library of LIBRARIES) {
        // add library
        libraryManager.add(library);

        // force even for empty ones to load not structured UMD libraries for example
        const preload = library.preload || [];
        if (preload) {
            await libraryManager.needs(library.id, preload);
        }
    }
    const {deltaWinHeight, deltaWinWidth} = settings.global.cssVariables || {};

    // Add css variables about width/height
    // These variables are need on android, when the viewport size change due to navBar show/hide
    window.addEventListener("resize", onResizeThrottled.bind(this, {deltaWinHeight, deltaWinWidth}));
    updateCssVariables({deltaWinHeight, deltaWinWidth});

    // load project entrypoint
    await run({libraryManager, configManager, options});
}

const decoratedComposer = loadOnEvent(composer);
export {decoratedComposer as composer};
