// Export only helpers used on all products
export * from "./debounce";
export * from "./throttle";
export * from "./deepMerge";
export * from "./loadScript";
export * from "./matcher";

// By default product don't use redirections,
// to use redirection export into the product the @foundation/helpers/initRedirectionManager
export const initRedirectionManager = async () => {};
