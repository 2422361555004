const translateNode = (locales, node) => {
    if (node.label) {
        node.label = getLabelTranslations(locales, node.label);
    }
    if (node.labelHelp) {
        node.labelHelp = getLabelTranslations(locales, node.labelHelp);
    }

    if (node.values) {
        node.values.forEach(translateNode.bind(this, locales));
    }
};

const getLabelTranslations = (locales, label) => {
    const labelTranslations = {};
    const labelPath = label.split(".");
    for (const lang of Object.keys(locales)) {
        labelTranslations[lang] = getLabelTranslationLang(locales[lang], labelPath);
        if (labelTranslations[lang] === undefined) {
            // Case translation not found, keep label
            labelTranslations[lang] = label;
        }
    }
    return labelTranslations;
};

const getLabelTranslationLang = (locale, labelPath) => {
    let node = locale;
    labelPath.forEach(key => {
        node = node && node[key];
    });
    return node;
};

function translateSettings(locales, settings) {
    // deep copy of settings
    const config = JSON.parse(JSON.stringify(settings));
    // The root settings must be handled as a group
    config.type = "group";
    translateNode(locales, config);
    return config;
}

export {translateSettings};
