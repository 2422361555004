import {loadScript} from "@foundation/helpers/loadScript";
import path from "path";
import {LOCAL_USER_CONFIG} from "@settings/configurationApp/localUserConfig";

const loadUMD = async dependency => {
    const {url, target} = dependency.source;
    let urlToLoad = url;

    // if source of the library already in cache do nothing
    if (window[dependency.id]) {
        return;
    }

    if (LOCAL_USER_CONFIG && LOCAL_USER_CONFIG.libraries && LOCAL_USER_CONFIG.libraries[dependency.id]) {
        // user's local config overrides the source url
        const appUmd = path.basename(url).replace(/\.min\.js/, ".js");
        urlToLoad = `http://${LOCAL_USER_CONFIG.host}:${LOCAL_USER_CONFIG.libraries[dependency.id]}/${appUmd}`;
    }

    // load the data from the source
    await loadScript({url: urlToLoad});

    // get the target
    const data = window[target];
    if (!data) {
        throw Error(`Cannot find the target ${target} loaded for dependency ${dependency.id}`);
    }
    delete window[target];

    // set the data into the dependency id
    window[dependency.id] = data;

    // now the code should be available via import in other apps declaring <dependency.id> as external
    // import {<content>} from "<dependency.id>";
};

export default loadUMD;
