import {makeInternationalization} from "@foundation/utils/i18n";

export default container => {
    container.declare("i18n", ({settings, logger}) => {
        // create an i18n instance able to load translations from here
        const {languages} = settings;
        return makeInternationalization({
            Vue: require("vue").default,
            defaultLocale: languages.default,
            fallbackLocale: languages.fallback,
            logger,
        });
    });
};
