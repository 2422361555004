import {Observable} from "@foundation/utils/observable";

const ALLOWED_EVENTS = ["created", "started", "activated", "deactivated", "stopped", "destroyed", "updated"];
const LAUNCHED_STATES = new Set(["started", "activated", "deactivated"]);

export default function makeServiceClass({logger}) {
    class Service {
        // logger for services
        static logger = logger.labels("Service");

        static observable = new Observable({
            events: [...ALLOWED_EVENTS, ...ALLOWED_EVENTS.map(item => `pre:${item}`)],
        });

        static TRANSITIONS = {
            create: new Set(["destroyed"]),
            start: new Set(["created", "stopped"]),
            activate: new Set(["deactivated", "started", "activated"]),
            deactivate: new Set(["started", "activated"]),
            stop: new Set(["started", "activated", "deactivated"]),
            destroy: new Set(["created", "started", "activated", "deactivated", "stopped"]),
            update: new Set(["deactivated", "started", "activated"]),
        };

        static METHOD_STATE = [
            ["create", "created"],
            ["start", "started"],
            ["activate", "activated"],
            ["deactivate", "deactivated"],
            ["stop", "stopped"],
            ["destroy", "destroyed"],
        ];

        static listen(...args) {
            this.observable.listen(...args);
        }

        static unlisten(...args) {
            this.observable.unlisten(...args);
        }

        // create application
        constructor({id, context, controller}) {
            this.id = id;
            this.controller = controller;
            this.state = "destroyed";
            this.children = [];
            this.context = context || {};
            this.observable = new Observable();
            this.logger = this.constructor.logger.labels(id);

            // dynamic creation of wrapper for transitions in service states
            for (const [method, state] of this.constructor.METHOD_STATE) {
                // eslint-disable-next-line no-loop-func
                this[method] = async function serviceWrapper(...args) {
                    if (!this.constructor.TRANSITIONS[method]?.has(this.state)) {
                        this.logger.warn(`Cannot go from state ${this.state} to ${state}`);
                        return;
                    }
                    this.constructor.observable.emit(`pre:${state}`, this);
                    await this.controller[method]?.(this, ...args);
                    this.logger.info(`Moved from state ${this.state} to ${state}`);
                    this.state = state;
                    this.constructor.observable.emit(state, this);
                    this.emit(state, this);
                }.bind(this);
            }
        }

        listen(...args) {
            this.observable.listen(...args);
        }

        unlisten(...args) {
            this.observable.unlisten(...args);
        }

        emit(event, ...args) {
            this.observable.emit(event, ...args);
        }

        link(app) {
            // add app to children of the application
            this.children.push(app);
            app.parent = this;

            // add listener to detect when the child application is destroyed or stopped
            const stopListener = () => {
                const index = this.children.findIndex(x => x === app);
                index >= 0 && this.children.splice(index, 1);
                app.unlisten("stopped", stopListener);
            };
            app.listen("stopped", stopListener);

            const destroyListener = () => {
                const index = this.children.findIndex(x => x === app);
                index >= 0 && this.children.splice(index, 1);
                app.unlisten("destroyed", destroyListener);
            };
            app.listen("destroyed", destroyListener);
        }

        // used to communicate a new state to a running application
        async update(event, data) {
            if (!this.constructor.TRANSITIONS.update?.has(this.state)) {
                this.logger.warn(`Cannot update application from state ${this.state}`);
                return;
            }
            this.constructor.observable.emit(`pre:updated`, this, event, data);
            await this.controller.update?.(this, event, data);
            this.logger.info(`Updated from state ${this.state} with event ${event}`);
            this.constructor.observable.emit("updated", this, event, data);
            this.emit("updated", this, event, data);
        }

        // helper to determine from external point of view if the service or
        // derived is launched, i.e. is started and activated or not
        isLaunched() {
            return LAUNCHED_STATES.has(this.state);
        }
    }

    return Service;
}
