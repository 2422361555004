import History from "@foundation/utils/history";

export default container => {
    container.declare(
        "history",
        dependencies =>
            // create history
            new History(dependencies)
    );
};
