export class PilotsRegister {
    constructor() {
        this.register = {};
    }

    add(id, cluster) {
        this.register[id] = cluster;
    }

    remove(id) {
        if (!this.register[id]) {
            return;
        }
        delete this.register[id];
    }

    get(id) {
        return this.register[id];
    }
}
