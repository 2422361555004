import {pilotsRegister as pilots} from "@focusComponents/FocusCluster";

export default container => {
    container.declare("focus", ({$rendering}) =>
        // extends register of cluster to allow to focus asynchronously due to
        // non rendered elements when setting context in state machine
        async (clusterId, itemId) => {
            // get the cluster
            const cluster = pilots.get(clusterId);
            if (!cluster) {
                throw Error(`Cannot find cluster ${clusterId} inside the register`);
            }

            // wait for rendering
            await $rendering();
            cluster.focus(itemId);
        }
    );
};
