import BasePilot from "./base";

const elementOrder = element => parseInt(element.dataset.order || "99999");
function isFocusable(item) {
    // prevent false positive of active cluster without item in pilot
    return item.isFocusable();
}
function getOrderedItems() {
    return Array.from(this.$ids.entries())
        .filter(item => isFocusable(item[1]))
        .sort((a, b) => {
            const orderA = elementOrder(a[1].$el);
            const orderB = elementOrder(b[1].$el);
            return orderA - orderB;
        })
        .map(x => x[0]);
}

function getFocusedIndex(focusedItemId, items) {
    // if no one is focused, return the first
    if (focusedItemId === undefined || focusedItemId === null) {
        return 0;
    }

    // find the index of the focused element to find its neighbour
    const index = items.findIndex(item => item === focusedItemId);
    return index === -1 ? undefined : index;
}

function next(focusedItemId) {
    let nextItem;
    // get items
    const items = getOrderedItems.call(this);

    // find the focused index
    const focusedIndex = getFocusedIndex(focusedItemId, items);

    // number of elements
    const size = items.length;
    const edgeOptions = this.$options?.edge;

    if (focusedIndex === size - 1 && (edgeOptions?.next === "handover" || edgeOptions?.south === "handover")) {
        // if focus is at the cluster edge and the option at the edge is handover
        // return so that focus control is handed over to the parent cluster
    } else {
        // the next index
        nextItem = items[Math.min(size - 1, focusedIndex + 1)];
    }
    return nextItem;
}

function previous(focusedItemId) {
    let previousItem;
    // get items
    const items = getOrderedItems.call(this);

    // find the focused index
    const focusedIndex = getFocusedIndex(focusedItemId, items);

    const edgeOptions = this.$options?.edge;
    if (focusedIndex === 0 && (edgeOptions?.previous === "handover" || edgeOptions?.north === "handover")) {
        // if focus is at the cluster edge and the option at the edge is handover
        // return so that focus control is handed over to the parent cluster
    } else {
        // the previous index
        previousItem = items[Math.max(0, focusedIndex - 1)];
    }
    return previousItem;
}

export default class LinearPilot extends BasePilot {
    // expose some APIs
    next(...args) {
        return next.call(this, ...args);
    }

    east(...args) {
        return next.call(this, ...args);
    }

    south(...args) {
        return next.call(this, ...args);
    }

    previous(...args) {
        return previous.call(this, ...args);
    }

    north(...args) {
        return previous.call(this, ...args);
    }

    west(...args) {
        return previous.call(this, ...args);
    }
}
