function needlemanWunschDistance(aA, aB) {
    let i;
    let j;
    let l;
    let AlignmentA;
    let AlignmentB;
    let Score;
    let ScoreDiag;
    let ScoreLeft;
    let ScoreUp;
    const holePenalty = 1;
    const matchBonus = 10;
    const A = `-${aA}`;
    const B = `-${aB}`;
    const F = [];
    for (i = 0; i < A.length; i += 1) {
        l = [];
        for (j = 0; j < B.length; j += 1) {
            l.push(0);
        }
        F.push(l);
    }
    for (i = 0; i < A.length; i += 1) {
        F[i][0] = holePenalty * i;
    }
    for (j = 0; j < B.length; j += 1) {
        F[0][j] = holePenalty * j;
    }

    for (i = 1; i < A.length; i += 1) {
        for (j = 1; j < B.length; j += 1) {
            F[i][j] = Math.max(
                F[i - 1][j - 1] + (A[i] === B[j] ? matchBonus : 0),
                F[i - 1][j] + holePenalty,
                F[i][j - 1] + holePenalty
            );
        }
    }
    AlignmentA = "";
    AlignmentB = "";
    i = A.length - 1;
    j = B.length - 1;
    while (i > 0 && j > 0) {
        Score = F[i][j];
        ScoreDiag = F[i - 1][j - 1];
        ScoreLeft = F[i - 1][j];
        ScoreUp = F[i][j - 1];
        if (Score === ScoreDiag + (A[i] === B[j] ? matchBonus : 0)) {
            AlignmentA = A[i] + AlignmentA;
            AlignmentB = B[j] + AlignmentB;
            i -= 1;
            j -= 1;
        } else if (Score === ScoreUp + holePenalty) {
            AlignmentA = `-${AlignmentA}`;
            AlignmentB = B[j] + AlignmentB;
            j -= 1;
        } else if (Score === ScoreLeft + holePenalty) {
            AlignmentA = A[i] + AlignmentA;
            AlignmentB = `-${AlignmentB}`;
            i -= 1;
        } else {
            i -= 1;
            j -= 1;
        }
    }

    while (i > 0) {
        AlignmentA = A[i] + AlignmentA;
        AlignmentB = `-${AlignmentB}`;
        i -= 1;
    }
    while (j > 0) {
        AlignmentB = B[j] + AlignmentB;
        AlignmentA = `-${AlignmentA}`;
        j -= 1;
    }
    Score =
        (A.length + B.length - 2 - AlignmentA.length - AlignmentA.length + Math.max(A.length, B.length)) /
        Math.max(A.length, B.length);
    return Score;
}

function normalizePhrase(aPhrase) {
    const words = aPhrase.toLowerCase().split(" ");
    words.sort();
    return words.join(" ");
}

function closestStringMatch(aNeedle, aHaystack) {
    let maxElt;
    let maxVal;
    let i;
    let d;
    maxVal = -1000;
    maxElt = 0;
    for (i = 0; i < aHaystack.length; i += 1) {
        d = needlemanWunschDistance(aNeedle, aHaystack[i]);
        if (d > maxVal) {
            maxElt = i;
            maxVal = d;
        }
    }
    return aHaystack[maxElt];
}

function bestMatches(aNeedle, aHaystack) {
    // returns an array of [string, score] couples sorted by score in descending order.
    // only matches with score > 0.25 are considered.
    let i;
    let d;

    const result = [];
    const normalized = normalizePhrase(aNeedle);
    for (i = 0; i < aHaystack.length; i += 1) {
        d = needlemanWunschDistance(normalized, normalizePhrase(aHaystack[i]));
        if (d > 0.25) {
            result.push([aHaystack[i], d]);
        }
    }
    return result.sort((a, b) => b[1] - a[1]);
}

export {bestMatches, closestStringMatch};
