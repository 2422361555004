import VueI18n from "vue-i18n";
import sanitizeHtml from "sanitize-html";

// sanitize the values in the Args used with $t(), $tc()
const sanitizeArgs = args => {
    const sanitizedArg = {};
    for (const key in args) {
        if (typeof args[key] === "object") {
            sanitizedArg[key] = sanitizeArgs(args[key]);
        } else {
            sanitizedArg[key] = sanitizeHtml(args[key]);
        }
    }
    return sanitizedArg;
};
export const makeInternationalization = ({Vue, defaultLocale, fallbackLocale, messages, logger}) => {
    // a flag to indicate if we polyfilled the Intl interface
    let isIntlPolyfilled = false;
    const LOGGER = logger.labels("i18n");

    // if Intl not available preload it
    if (!global.Intl) {
        // Load intl polyfill
        global.Intl = require("intl");
        isIntlPolyfilled = true;
    }

    // install i18n into Vue
    Vue.use(VueI18n);

    // create i18n instance
    const i18n = new VueI18n({
        locale: defaultLocale,
        fallbackLocale,
        messages,
    });

    // already loaded languages
    const loadedLanguages = new Map();
    const loadLocaleRegister = [];
    const changeLocaleRegister = [];

    // to subscribe to loading locale messages if required
    i18n.subscribeLoad = callback => {
        loadLocaleRegister.push(callback);
        loadedLanguages.set(callback, new Set());
    };

    // unsubscribe to loading new messages
    i18n.unsubscribeLoad = callback => {
        // remove loader
        const index = loadLocaleRegister.indexOf(callback);
        index > -1 && loadLocaleRegister.splice(index, 1);
        loadedLanguages.delete(callback);
    };

    // to subscribe to changing locale
    i18n.subscribeChange = callback => {
        changeLocaleRegister.push(callback);
    };

    // unsubscribe to changing local
    i18n.unsubscribeChange = callback => {
        // remove subscriber
        const index = changeLocaleRegister.indexOf(callback);
        index > -1 && changeLocaleRegister.splice(index, 1);
    };

    // extend i18n to add method to change the language
    i18n.setLanguage = async function setLanguage(lang) {
        // load languages if necessary
        await this.loadLanguage(lang);

        // change locale of i18n once all has been correctly loaded
        this.locale = lang;

        // Notify locale registers that language has chnaged
        for (const localeChangeRegister of changeLocaleRegister) {
            await localeChangeRegister(lang);
        }

        // change locale of the browser
        document.querySelector("html").setAttribute("lang", lang);
    };

    i18n.loadLanguage = async function loadLanguage(lang) {
        // if we use polyfill for Intl, we need to load the data that the package provide in an async fashion
        // to not load all locales via require
        if (isIntlPolyfilled) {
            await import(/* webpackChunkName: "intl-polyfill-[request]" */ `intl/locale-data/jsonp/${lang}.js`);
        }

        // If the language hasn't been loaded yet for this loader, merge it
        for (const localeLoader of loadLocaleRegister) {
            // do nothing if already loaded
            const loadedLanguage = loadedLanguages.get(localeLoader);
            if (loadedLanguage && !loadedLanguage.has(lang)) {
                let messages;
                try {
                    // load and merge messages
                    messages = {
                        ...(await localeLoader(lang)),
                    };
                } catch (e) {
                    LOGGER.warn(`missing translation for ${lang}`);
                    messages = {};
                }

                if (messages.dateTimeFormats) {
                    this.mergeDateTimeFormat(lang, messages.dateTimeFormats);
                    delete messages.dateTimeFormats;
                }
                this.mergeLocaleMessage(lang, messages);

                // indicate that language loaded for this handler
                loadedLanguage.add(lang);
            }
        }
    };

    // add a placeholder method and mixin
    i18n.tph = (key, placeholder) => (i18n.te(key) ? i18n.t(key) : placeholder || "");

    i18n.tSanitize = (key, args) => i18n.t(key, sanitizeArgs(args));
    i18n.tcSanitize = (key, count, args) => i18n.tc(key, count, sanitizeArgs(args));
    Vue.mixin({
        created() {
            this.$tph = i18n.tph;
            this.$tSanitize = i18n.tSanitize;
            this.$tcSanitize = i18n.tcSanitize;
        },
        destroyed() {
            this.$tph = null;
            this.$tSanitize = null;
            this.$tcSanitize = null;
        },
    });

    return i18n;
};
