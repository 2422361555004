import {deepMergeOverrideArray} from "@foundation/helpers/deepMerge";

import {overrideBackendsWithModes, overrideClients} from "./backends";

export default async (container, options) => {
    const {configManager} = container;
    const globalSettings = await configManager.loadConfig("settings.json");
    const settingOverride = await configManager.loadConfig("settings.override.json");
    const applications = await configManager.loadConfig("applications.json");
    const applicationsConfig = await configManager.loadConfig("applicationsConfig.json");
    const applicationsExternal = await configManager.loadConfig("applicationsExternal.json");
    const backendsSettings = await configManager.loadConfig("backends.json");
    const services = await configManager.loadConfig("services.json");
    container.declare("settings", () => {
        // merge several settings into a single one
        const settings = deepMergeOverrideArray(
            {
                ...globalSettings,
                ...backendsSettings,
                ...applications,
                ...applicationsConfig,
                ...applicationsExternal,
                ...services,
                version: process.env.APP_VERSION,
                versionDate: process.env.APP_VERSION_DATE,
            },
            settingOverride
        );

        let overrideSettings;
        if (!process.env.DISABLE_URL_OVERRIDE) {
            overrideSettings = require("./debug/overrideSettings").default;
            // override them with URL params if needed
            overrideSettings(settings, options);
        }

        // since we have the possibility to override settings with modes files, we need to take into account the
        // possible override from the URL first, then loads other files via modes and reoverride in case the user wants
        // to override the settings in modes specific files
        overrideBackendsWithModes(settings);
        overrideClients(settings);

        // override them with URL params if needed
        overrideSettings && overrideSettings(settings, options);

        return settings;
    });
};
