const createProxy = (handler, path) => {
    // if no path initially, create an empty stack and also consider
    const parentPath = path || [];
    const proxy = new Proxy(
        async (...args) =>
            handler({
                path: parentPath,
                args,
            }),
        {
            get(obj, prop) {
                if (obj[prop]) {
                    return obj[prop];
                }
                parentPath.push(prop);
                return proxy;
            },
        }
    );
    return proxy;
};

export class Pather {
    constructor(wrappedObj, handler) {
        // eslint-disable-next-line no-constructor-return
        return new Proxy(wrappedObj, {
            get(obj, prop) {
                if (obj[prop]) {
                    return obj[prop];
                }
                return createProxy(handler, [prop]);
            },
        });
    }
}
