import Stater from "./stater";

// normalize entries of mappers
// input -> output
// [1, 2, 3] -> [{key: 1, value: 1}, {key: 2, value: 2}, {key: 3, value: 3}]
// {a: 1, b: 2, c: 3} -> [{key: a, value: 1}, {key: b, value: 2}, {key: c, value: 3}]
const normalize = map => {
    if (!(Array.isArray(map) || isObject(map))) {
        throw Error("Not valid map provided for normalization");
    }
    return Array.isArray(map)
        ? map.map(item => ({key: item, value: normalizeItem(item)}))
        : Object.keys(map).map(item => ({key: item, value: normalizeItem(map[item])}));
};

const isObject = obj => obj !== null && typeof obj === "object";
const normalizeItem = item => {
    if (typeof item === "string") {
        return {
            name: item,
        };
    }
    if (typeof item === "function") {
        return {
            func: item,
        };
    }
    if (isObject(item)) {
        return item;
    }
    throw Error("Invalid mapper type");
};

// a helper to access machine getters
export const mapGetters = (namespace, options) => {
    // normalize actions
    const actions = normalize(options);

    return actions.reduce((acc, {key, value}) => {
        // define the function that will be called
        acc[key] = function mapGettersCB(...args) {
            // get the store of the namespace
            const store = this.$store.manager.get(namespace);
            if (!store) {
                throw new Error(`Can't find machine namespace ${namespace} for getter ${key}`);
            }

            // create the stater, an object faking the matches of the state to keep a trace of the states needed by a
            // getter
            const stater = new Stater({state: store.currentState, tracker: store.tracker});

            // call the getter and retrieve the resulting value
            const getter = store.service.machine.options.getters[value.name];
            if (!getter) {
                throw Error(`Cannot get getter ${value.name} for machine ${namespace}`);
            }
            const result = getter.call(this, store.service.machine.context, stater, ...args);

            // get states dependencies
            const dependencies = stater.fetch();

            // call the tracker to set dependencies in states for this getter
            store.tracker.setDependencies(value.name, dependencies);

            // return the value of the getter
            return result;
        };
        return acc;
    }, {});
};

// helper to access state
export const mapState = (namespace, options) => {
    // normalize actions
    const actions = normalize(options);

    // options can be an array of strings to map to the state/observable or an object
    // for mapping a state property to the key of the object, or a key to a function with the state
    // provided as first argument
    return actions.reduce((acc, {key, value}) => {
        // define the function that will be called
        acc[key] = function mapStateCB(...args) {
            // get the store of the namespace
            const store = this.$store.manager.get(namespace);
            if (!store) {
                throw new Error(`Can't find machine namespace ${namespace} for state ${key}`);
            }
            return value.func
                ? value.func.call(this, store.service.machine.context, ...args)
                : store.service.machine.context[value.name];
        };
        return acc;
    }, {});
};

// helpers to execute as actions for transitioning
export const mapActions = (namespace, options) => {
    // normalize actions
    const actions = normalize(options);

    return actions.reduce((acc, {key, value}) => {
        // define the function that will be called
        acc[key] = function mapActionsCB(payload) {
            // get the store of the namespace
            const store = this.$store.manager.get(namespace);
            if (!store) {
                throw new Error(`Can't find machine namespace ${namespace} for action ${key}`);
            }

            // call the service with the transition associated to the action
            return store.service.send.call(store.service, value.name, {
                payload,
            });
        };
        return acc;
    }, {});
};

// retrieve the helpers for a given machine (the name of the machine will be used as a namespace)
export const createNamespacedHelpers = namespace => ({
    mapGetters: mapGetters.bind(null, namespace),
    mapState: mapState.bind(null, namespace),
    mapActions: mapActions.bind(null, namespace),
});
/**
 * Register on bus the method to call to send an event to the stateMachine
 * @param store
 * @param bus
 * @param path
 * @param methods
 * @param getRegisteredMethod method returning the callBack to register for a transition
 */
const bindStoreToBusHelper = (store, bus, path, methods, getRegisteredMethod) => {
    // transform path into a bus object path
    const objectPath = path.split(".");

    // now for all defined methods, register them in the bus object and call the service with associated
    // transitions
    for (const method of Object.keys(methods)) {
        // get the associated transition
        const transition = methods[method];

        // get the bus object of the path
        const object = objectPath.reduce((object, key) => object[key], bus);

        // register the method
        object[method].register(getRegisteredMethod(transition));
    }
};

/**
 * Register a list of methods on the bus
 * @param store
 * @param bus
 * @param path: path to register on the bus
 * @param methods Object { <path to add on bus>: <event to send on stateMachine with data {payload}>}
 * example
 *   - path = "toto"
 *   - methods = { setState: "SET_STATE" }
 * will register: bus.toto(myData) => stateMachine will receive event "SET_STATE" with data on event.
 *    - event.type = SET_STATE"
 *    - event.payload = myData
 * registered method bus.toto(myData) send an event without handling event result
 */
export const bindStoreToBus = (store, bus, path, methods) => {
    bindStoreToBusHelper(
        store,
        bus,
        path,
        methods,
        transition => args =>
            store.service.send.call(store.service, transition, {
                payload: args,
            })
    );
};

/**
 * Register a list of async methods on the bus
 * @param store
 * @param bus
 * @param path: path to register on the bus
 * @param methods Object { <path to add on bus>: <event to send on stateMachine with data {payload}>}
 * example
 *   - path = "toto"
 *   - methods = { setState: "SET_STATE" }
 * will register: bus.toto(myData) => stateMachine will receive event "SET_STATE" with data on event.
 *    - event.type = SET_STATE"
 *    - event.payload = myData
 *    - event.resolve = method to call to resolve the promise returned by the method registered on the bus
 *    - event.reject  = method to call to reject  the promise returned by the method registered on the bus
 *  registered method bus.toto(myData) return the promise handled by the event with event action result
 */
export const bindStoreToBusAsync = (store, bus, path, methods) => {
    bindStoreToBusHelper(
        store,
        bus,
        path,
        methods,
        transition => async args =>
            new Promise((resolve, reject) => {
                store.service.send.call(store.service, transition, {
                    payload: args,
                    resolve,
                    reject,
                });
            })
    );
};
