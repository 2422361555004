// class used simulate the behaviour of the state from xstate, while
// registering call dependencies on some states from getters
export default class Stater {
    #tracker;

    #state;

    #dependencies;

    constructor({tracker, state}) {
        this.#dependencies = new Set();

        // the xstate state to wrap
        this.#state = state;

        // the context contains the state value in an observable/reactive value
        this.#tracker = tracker;

        // thanks ES6...
        this.matches = this.matches.bind(this);
    }

    matches(parentStateValue) {
        // FIXME for working with objects, we will need to normalize those states for more complex cases to uniquely
        // identify them and register them correctly inside the dependencies

        // in case we do not have yet the current state set in the reactive
        // context, we compute it, and update it in the context from the
        // current state

        // eslint-disable-next-line no-prototype-builtins
        if (!this.#tracker.context.hasOwnProperty(parentStateValue)) {
            this.#tracker.setContextValue(parentStateValue, this.#state.matches(parentStateValue));
        }

        // the result is the one taken from the context
        const result = this.#tracker.getContextValue(parentStateValue);

        // add this parent state value into dependencies
        this.#dependencies.add(parentStateValue);

        return result;
    }

    // return states called during matches
    fetch() {
        return this.#dependencies;
    }

    // flush dependencies calls
    flush() {
        this.#dependencies.clear();
    }
}
