export default class Container {
    constructor() {
        this.register = {};
    }

    declare(name, cb) {
        Object.defineProperty(this, name, {
            get: () => {
                // eslint-disable-next-line no-prototype-builtins
                if (!this.register.hasOwnProperty(name)) {
                    this.register[name] = cb(this);
                }

                return this.register[name];
            },
            configurable: true,
            enumerable: true,
        });

        return this;
    }
}
