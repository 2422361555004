import {debounce} from "./debounce";

export const throttle = (func, limit) => {
    let throttling = false;
    function throttler(...args) {
        if (!throttling) {
            func.apply(this, args);
            throttling = true;
            setTimeout(() => {
                throttling = false;
            }, limit);
        }
    }
    throttler.reset = () => {
        throttling = false;
    };
    return throttler;
};

// same as throttle, but starting filtering calls after an initial delay after the first call
export const throttleAfterDelay = (func, limit, startDelay) => {
    let timeout;
    let throttling = false;
    let state = "stopped";

    const stopRunning = debounce(() => {
        state = "stopped";
    }, limit);
    function throttler(...args) {
        switch (state) {
            case "stopped":
                func.apply(this, args);
                state = "starting";
                timeout = setTimeout(() => {
                    state = "running";
                    stopRunning();
                }, startDelay);
                break;
            case "starting":
                func.apply(this, args);
                break;
            case "running":
                stopRunning();
                if (!throttling) {
                    func.apply(this, args);
                    throttling = true;
                    setTimeout(() => {
                        throttling = false;
                    }, limit);
                }
                break;
            default:
                break;
        }
    }
    throttler.reset = () => {
        throttling = false;
        state = "stopped";
        clearTimeout(timeout);
    };
    return throttler;
};
