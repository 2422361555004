import {Detector, testers} from "@foundation/utils/detector";

export default container => {
    container.declare("detector", ({settings, apis}) => {
        // create detector instance
        const detector = new Detector();

        // add touch detection
        detector.add(testers.interaction.bind(null, apis));

        // add environment detection
        detector.add(testers.environment.bind(null, apis));

        if (settings?.global?.disableAnimations) {
            detector.update("no-anim", true);
        }

        return detector;
    });
};
