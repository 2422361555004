export default class StateTracker {
    #delete;

    #set;

    #gettersOfStates;

    #statesOfGetters;

    constructor({context, $delete, $set}) {
        // register to get the getters depending on a state
        this.#gettersOfStates = {};

        // register to get the states on which a getter depends on
        this.#statesOfGetters = {};

        // the reactive data and utilitaries
        this.context = context;
        this.#delete = $delete;
        this.#set = $set;
    }

    add(getter, state) {
        // get getters depending on this state
        this.#gettersOfStates[state] = this.#gettersOfStates[state] || new Set();

        // add this getter as dependency
        this.#gettersOfStates[state].add(getter);

        // do the same for the states on which a getter depends on
        this.#statesOfGetters[getter] = this.#statesOfGetters[getter] || new Set();

        // add this getter as dependency
        this.#statesOfGetters[getter].add(state);
    }

    // when a getter doesn't depend on a state anymore
    remove(getter, state) {
        // get getters depending on this state
        this.#gettersOfStates[state] = this.#gettersOfStates[state] || new Set();
        const gettersOfState = this.#gettersOfStates[state];
        gettersOfState.delete(getter);

        // if states has no more getters associated to it, remove it from the context
        if (!gettersOfState.size) {
            this.#delete(this.context, getter);
        }

        // do the same for the states on which a getter depends on
        this.#statesOfGetters[getter] = this.#statesOfGetters[getter] || new Set();
        this.#statesOfGetters[getter].delete(state);
    }

    // reset dependencies for a getter
    setDependencies(getter, states) {
        // get current states dependencies for the getter and remove them if not present in new state dependencies
        const statesOfGetter = this.#statesOfGetters[getter];
        if (statesOfGetter) {
            // loop on states of getters
            for (const state of statesOfGetter) {
                // if state no more present in new dependencies, remove it
                if (!states.has(state)) {
                    this.remove(getter, state);
                }
            }
        }

        // add those states as dependencies of the getter
        for (const state of states) {
            this.add(getter, state);
        }
    }

    setContextValue(key, value) {
        this.#set(this.context, key, value);
    }

    getContextValue(key) {
        return this.context[key];
    }

    // to update values in the reactive context of state getters when the xstate state changes
    updateContext(state) {
        for (const key of Object.keys(this.context)) {
            this.#set(this.context, key, state.matches(key));
        }
    }
}
