/** ****************************************************
 *
 * Services could be defined:
 * - on product level and present on this folder
 * - on foundation common level (commonServices)
 *
 ***************************************************** */
const commonServices = [
    "splashScreen",
    "settings",
    "globalSettings",
    "appManager",
    "windowManager",
    "vue",
    "boot",
    "keysListener",
];
export {commonServices};
