import RedirectionManager from "@foundation/utils/redirectionManager";
import {makeLogger} from "@foundation/utils/loggers";

export const initRedirectionManager = async container => {
    // do not use the logger of the application intentionnaly since the app logger uses settings that can be
    // overrided by URL, and we need to catch and modify URL potentially by redirection before that settings can
    // read them. The config of loggers can be different with this method, but since it is for some few logs it is
    // acceptable
    const redirectionManager = new RedirectionManager({logger: makeLogger({}).labels("redirection-manager")});
    container.declare("redirectionManager", () => redirectionManager);
    const redirections = require("@redirections").default;
    await redirectionManager.updateRedirection(redirections);
};
