import {translateSettings} from "@foundation/utils/translateSettings";
import Router from "@foundation/utils/router/Router";

function makeAppController({i18n, Vue, settings, $store, logger}) {
    class AppController {
        loadLocale() {}

        loadSettings() {}

        async create(app) {
            this.onMachineEvent = this.machineEventHandler.bind(this, app);
        }

        async start(app, {args} = {}) {
            let componentInstance;
            // logger for the application
            const appLogger = logger.labels("app").labels(app.id);

            // get i18n for translations
            i18n.subscribeLoad(this.loadLocale);

            // set the language to what is selected for now from the parent and the fallback
            await i18n.loadLanguage(i18n.fallbackLocale);
            await i18n.loadLanguage(i18n.locale);

            // get store and component
            const {store, component} = await this.getAppData();
            const ComponentExtended = Vue.extend(component);

            if (store) {
                // update store context with parameters provided
                for (const arg of Object.keys(args || {})) {
                    store.service.machine.context[arg] = args[arg];
                }

                // start machine
                store.start();
                this.store = store;

                // listen for events on the machine
                this.store.service.onEvent(this.onMachineEvent);

                // get data for the router if the application uses it
                const routes = this.getAppRoutes ? await this.getAppRoutes() : [];
                const router = new Router({routes, store: this.store, $store, logger: appLogger});

                // create component instance from component
                componentInstance = new ComponentExtended({i18n, router});
            } else {
                componentInstance = new ComponentExtended({i18n});
            }

            // add an element acting as entry point to avoid problem with vue replacing the entrypoint provided when
            // mounting
            const node = document.createElement("div");

            // append content to entrypoint
            app.node.appendChild(node);

            // mount vue instance into our created entrypoint
            componentInstance.$mount(node);

            // store references
            this.component = componentInstance;
            this.app = app;
        }

        async stop() {
            if (this.component) {
                // destroy component base destroy doesn't destroy router
                this.component.$router.destroy();
                this.component.$destroy();
                this.component.$el.remove();
            }
            i18n.unsubscribeLoad(this.loadLocale);
            delete this.component;
            if (this.store) {
                this.store.service.off(this.onMachineEvent);
                this.store.stop();
                delete this.store;
            }
        }

        async activate() {
            // Do nothing, default behavior
        }

        async deactivate() {
            // Do nothing, default behavior
        }

        async show() {
            // Do nothing, default behavior
        }

        async hide() {
            // Do nothing, default behavior
        }

        async destroy() {
            delete this.onMachineEvent;
        }

        async update(app, event, payload) {
            this.store?.service?.send(event, {payload});
        }

        isPortal() {
            return false;
        }

        machineEventHandler(app, event) {
            switch (event.type) {
                case "APP_EMIT":
                    app.emit(event.data);
                    break;
                case "APP_STOP":
                    app.stop();
                    break;
                case "APP_LINK":
                    event.data.apps?.forEach(child => app.link(child));
                    break;
                default:
                    break;
            }
        }

        async getSettings() {
            const userSettings = await this.loadSettings();
            let config;
            if (userSettings) {
                const locales = {};
                for (let indexLang = 0; indexLang < settings.languages.available.length; indexLang += 1) {
                    const lang = settings.languages.available[indexLang];
                    try {
                        locales[lang] = await this.loadLocale(lang);
                        locales[lang] = locales[lang] && locales[lang].default;
                    } catch (e) {
                        locales[lang] = {};
                    }
                }
                config = translateSettings(locales, userSettings.default);
            }
            return config;
        }
    }
    return AppController;
}

export {makeAppController};
