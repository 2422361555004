const cache = {__esModule: true};
const exportFoundation = async () => {
    // We want export all modules from foundation
    // eslint-disable-next-line import/extensions
    const sharedModules = await import("@/");
    const sharedComponents = await import("@components");

    for (const module of Object.keys(sharedModules)) {
        cache[module] = await sharedModules[module];
        if (cache[module].default) {
            cache[module] = cache[module].default;
        }
    }

    window.foundation = new Proxy(
        {},
        {
            get(target, name) {
                if (name === "sharedComponents") {
                    return sharedComponents;
                }
                const symbol = cache[name] || sharedComponents[name];
                if (!symbol) {
                    throw Error(`Missing requested symbol ${name}`);
                }
                return symbol;
            },
        }
    );
};

export default exportFoundation;
