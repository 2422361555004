import featuresFlags from "./testers/featuresFlags";
import interaction from "./testers/interaction";
import environment from "./testers/environment";

function updateClass(root, name, value) {
    const classToApply = value ? name : `no-${name}`;
    const classToRemove = value ? `no-${name}` : name;

    // list of classes already applied
    const classList = root.className ? root.className.split(/\s+/) : [];

    // if class to add already present, do nothing
    let index = classList.findIndex(item => item === classToApply);
    if (index >= 0) {
        return;
    }

    // else remove the opposite class and add it
    index = classList.findIndex(item => item === classToRemove);
    if (index >= 0) {
        classList.splice(index, 1);
    }
    classList.push(classToApply);

    // apply classes on root node
    root.className = classList.join(" ");
}

export class Detector {
    #listeners;

    #root;

    constructor() {
        // listeners
        this.#listeners = new Set();

        // the html tag on which we apply classes of the detector
        [this.#root] = document.getElementsByTagName("html");

        // features informations
        this.features = {};
    }

    subscribe(listener) {
        this.#listeners.add(listener);
    }

    unsubscribe(listener) {
        this.#listeners.delete(listener);
    }

    // add a test to perform for the feature detection
    add(tester) {
        // call the tester and not wait for result, not useful at the detector level
        tester(this);
    }

    // update the detector
    update(name, value) {
        // class to apply and class to not apply only if value is a boolean
        updateClass(this.#root, name, value);

        // store value on detector
        this.features[name] = value;

        // inform listeners that the value changed
        for (const listener of this.#listeners) {
            listener(name, value);
        }
    }
}
export const testers = {
    featuresFlags,
    interaction,
    environment,
};
