import BasePilot from "./base";

/**
 * Specific pilot for grid component navigation.
 * Navigation Options:
 *  'positionInfo' position information provided by grid component
 *  'edge' Object with:
 *            - keys: "north", "south", "east" or "west"
 *            - value "handover" to delegate the navigation on parent when the specified edge is reach
 *
 */

function upDown(originRowId, originItemId, direction) {
    const {positionInfo} = this.$options;
    const nextRowId =
        positionInfo.rows[originRowId] && direction === "north"
            ? positionInfo.rows[originRowId].prev
            : positionInfo.rows[originRowId].next;
    let destinationKey;
    let destinationDistance;

    if (nextRowId !== undefined && nextRowId !== originRowId) {
        const rowData = positionInfo.rowsData[originRowId];
        const nextRowData = positionInfo.rowsData[nextRowId];
        // will search on nextRowData the destinationID
        if (rowData && nextRowData) {
            const nextRowItems = nextRowData.items;
            let itemPosition;

            if (this.startPosition === null) {
                this.startPosition = rowData.items[originItemId].position - rowData.containerStart;
            }
            const itemDataOffset = this.startPosition + nextRowData.containerStart;

            for (const itemId of Object.keys(nextRowItems)) {
                itemPosition = nextRowItems[itemId].position;
                const distance = Math.abs(itemDataOffset - itemPosition);
                if (!destinationKey || distance < destinationDistance) {
                    destinationDistance = distance;
                    destinationKey = itemId;
                }
            }
            if (destinationKey) {
                destinationKey = `${nextRowId}|${destinationKey}`;
            }
        }
    }
    return destinationKey;
}

function navigate(originKey, heading) {
    const [originRowId, originItemId] = originKey.split("|");
    let destinationKey;

    // the different cases of actions handled
    switch (heading) {
        case "north":
        case "south":
            destinationKey = upDown.call(this, originRowId, originItemId, heading) || originKey;
            break;
        case "east":
            this.startPosition = null;
            destinationKey = this.$options.positionInfo.rowsData[originRowId].items[originItemId].next;
            break;
        case "west":
            this.startPosition = null;
            destinationKey = this.$options.positionInfo.rowsData[originRowId].items[originItemId].prev;
            break;
        default:
            destinationKey = originKey;
            break;
    }
    if (destinationKey === originKey && this.$options.edge && this.$options.edge[heading] === "handover") {
        // if focus is at the cluster edge and the option at the edge is handover
        // return so that focus control is handed over to the parent cluster
        destinationKey = undefined;
    }
    return destinationKey;
}

export default class GridPilot extends BasePilot {
    constructor(options) {
        super(options);
        this.startPosition = null; // used to keep vertical starting position, reset on horizontal move
    }

    north(originKey) {
        return navigate.call(this, originKey, "north");
    }

    east(originKey) {
        return navigate.call(this, originKey, "east");
    }

    west(originKey) {
        return navigate.call(this, originKey, "west");
    }

    south(originKey) {
        return navigate.call(this, originKey, "south");
    }
}
