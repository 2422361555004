import {deepMerge} from "@foundation/helpers/deepMerge";

// an env config for client or backend can override the default one if their names are identical
const mergeConfig = (envClients, defaultClients) => {
    // function to add clients to map of name and client
    const mapper = (map, client) => {
        map[client.name] = deepMerge(map[client.name] || {}, client);
        return map;
    };

    // create map of names and client instances
    const map = defaultClients.reduce(mapper, {});

    // loop on env clients, and replace them in map if present, else add the new client
    envClients.reduce(mapper, map);

    // create array from clients
    return Object.keys(map).map(key => map[key]);
};

export const overrideBackendsWithModes = settings => {
    if (settings.global?.modes?.length) {
        // loop on modes and try to read the data and override backends and clients defined in settings
        settings.global.modes.forEach(mode => {
            try {
                // eslint-disable-next-line import/no-dynamic-require
                const envSettings = require(`@settings/backends.${mode}.json`);
                settings.clients = mergeConfig(envSettings.clients || [], settings.clients || []);
                settings.backends = mergeConfig(envSettings.backends || [], settings.backends || []);
            } catch (e) {
                // do nothing if the file backends.${mode}.json don't exist, it's a normal behavior
            }
        });
    }
};

export const overrideClients = settings => {
    if (settings.global?.deviceType) {
        settings.clients.forEach(client => {
            client.options.deviceType = settings.global.deviceType;
        });
    }
};
