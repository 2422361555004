export default async apis => {
    const infos = await apis.platform.getInfo();
    // const handleKeyboard = function() {
    //     detector.update("keyboard", true);
    //     detector.update("use-focus", true);
    // };

    // Case input=mouse and we detect keyboard
    if (infos.input === "mouse") {
        // add listeners for keydown to detect this interaction mode
        // FIXME: re-activate the detection of keyboard on PC case when we define the focus for this modes
        // window.addEventListener("keydown", handleKeyboard, true);
    }

    // FIXME: when we know how detect the 'magic mouse' LG, add detector to set "mouse" to true
};
