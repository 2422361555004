const COLOR_LEVELS = {
    error: "\u001b[31m",
    warn: "\u001b[33m",
    info: "\u001b[32m",
    log: "\u001b[35m",
    debug: "\u001b[36m",
};

const LOG_LEVELS = {
    debug: 1,
    log: 2,
    info: 3,
    warn: 4,
    error: 5,
};

export const makeLogger = options => {
    const labels = options?.labels;
    const level = options?.level || 0;
    const minLevel = LOG_LEVELS[level];

    const isLabelMatching = function isLabelMatching(label) {
        return this.label.startsWith(label);
    };

    const loggerCallWrapper = function loggerCallWrapper(target, prop, message) {
        // if logs are disabled, do nothing
        if (options?.disabled) {
            return;
        }

        // if level is inferior to minimal level, do nothing
        if (LOG_LEVELS[prop] < minLevel) {
            return;
        }

        // get the label to display
        const label = target.tags?.join(".");

        // if label defined
        if (label) {
            // and if label is not in allowed labels prefixes, do nothing
            if (labels?.allowed?.length && !labels.allowed.some(isLabelMatching, {label})) {
                return;
            }

            // if label in excluded labels, do nothing
            if (labels?.excluded?.length && labels.excluded.some(isLabelMatching, {label})) {
                return;
            }
        }

        // simply call logger
        const timestamp = new Date().toISOString();
        const str = `${timestamp} ${COLOR_LEVELS[prop]}${prop}\u001b[39m ${label} ${message}`;
        // eslint-disable-next-line no-console
        console[prop]?.(str);
    };

    function labeledLogger(...args) {
        return new Proxy(
            {
                tags: args,
            },
            {
                get: (target, prop) => {
                    // we call labels of the logger, so we create another logger with new tags for labels
                    if (prop === "labels") {
                        return labeledLogger.bind(this, ...target.tags);
                        // property not in target object, and property in levels supported by winston logger
                        // we need to create a function to wrap levels calls and keep it inside the target
                    }
                    if (!(prop in target) && prop in COLOR_LEVELS) {
                        target[prop] = loggerCallWrapper.bind(this, target, prop);
                    }
                    // simple proxy to target else
                    return target[prop];
                },
            }
        );
    }

    const logger = labeledLogger();

    return logger;
};
