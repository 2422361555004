export default container => {
    container.declare("apis", ({i18n, settings, logger, capabilitiesConfigManager}) => {
        // create map of apps definitions
        const appsDef = (settings.applications || []).reduce((apps, app) => {
            apps[app.id] = app;
            return apps;
        }, {});

        // clean application settings to keep only what is expected by sava
        const savaSettings = {
            global: settings.global,
            browserCapabilities: settings.browserCapabilities,
            xstateInspector: new Set(settings.xstateInspector?.machines?.apis || []),
            preInstalledApps: {
                appList: (settings.preInstalledApplications || []).map(appId => appsDef[appId]).filter(Boolean),
                defaultAppSource: settings.defaultAppSource,
                appsWhitelist: settings.externalApplications?.whitelist || {},
                externalAppStoreWhitelist:
                    settings.externalApplications?.externalAppStoreWhitelist?.[settings?.global?.deviceType] || {},
            },
        };

        const {Functional, Manager} = window.sava;

        // create the manager of backends, clients etc
        const manager = new Manager({logger, context: {config: savaSettings}, capabilitiesConfigManager});

        // create the functional APIs with good context
        const functional = new Functional({
            context: {
                manager,
                config: savaSettings,
            },
            logger,
        });
        functional.HGW = function functionalHGW(deviceId) {
            return functional.callContext({deviceType: "hgw", deviceId});
        };
        functional.STB = function functionalSTB(deviceId) {
            return functional.callContext({deviceType: "stb", deviceId});
        };

        // Fixme: the init is call here, but we should use asynchronous code to be correct
        return functional;
    });
};
