export const debounce = (func, wait, immediate) => {
    let timeout;
    let later;

    const debouncer = function debouncerFct(...args) {
        later = function laterFct() {
            timeout = null;
            // if (!immediate) {
            func.apply(this, args);
            // }
        }.bind(this);
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(this, args);
        }
    };
    debouncer.stop = () => {
        clearTimeout(timeout);
        later = null;
        timeout = null;
    };
    return debouncer;
};

// delay execution of a function except if it is called again, the later call will be forced before starting waiting for
// the next one
export const debounceInvert = (func, delay) => {
    let timeout;
    let later;
    const debouncer = function debouncerFct(...args) {
        // stop current waiting
        clearTimeout(timeout);

        // function already waiting, so execute it and clean
        if (later && timeout) {
            later();
        }

        // define the function to call in delay
        later = function laterFct() {
            timeout = null;
            func.apply(this, args);
        }.bind(this);

        timeout = setTimeout(later, delay);
    };
    debouncer.stop = () => {
        clearTimeout(timeout);
        later = null;
        timeout = null;
        later = null;
    };
    return debouncer;
};
