const getArguments = args => {
    let event;
    let listener;
    if (args.length > 1) {
        [event, listener] = args;
    } else if (args.length === 1) {
        const [data] = args;
        if (typeof data === "function") {
            listener = data;
        } else if (typeof data === "string") {
            event = data;
        }
    }
    return {event, listener};
};

export class Observable {
    constructor({events} = {}) {
        this.listeners = {};
        this.allListeners = new Set();

        // create a Set of authorized events if provided
        this.events = events ? new Set(events) : undefined;
    }

    listen(...args) {
        const {event, listener} = getArguments(args);
        if (!(typeof listener === "function")) {
            throw Error("A provided listener isn't a function");
        }
        if (!event || !this.events || this.events.has(event)) {
            let listeners;
            if (!event) {
                listeners = this.allListeners;
            } else {
                listeners = this.listeners[event] || new Set();
                this.listeners[event] = listeners;
            }
            listeners.add(listener);
        }
    }

    unlisten(...args) {
        const {event, listener} = getArguments(args);
        // case clear all
        if (!event && !listener) {
            this.listeners = {};
            this.allListeners.clear();
            return;
        }

        // case clear all for an event
        if (event && !listener) {
            delete this.listeners[event];
            return;
        }

        // case clear the listener for all events
        if (!event && listener) {
            this.allListeners.delete(listener);
        }

        // clear listener for specified event
        if (event && listener) {
            this.listeners[event]?.delete(listener);
        }
    }

    emit(event, ...args) {
        // if event restriction defined, check that event exists before emission
        if (this.events && !this.events.has(event)) {
            return;
        }

        // listeners listening for needs to be called with the event being emitted as information
        for (const listener of this.allListeners) {
            listener(event, ...args);
        }

        // if event not in listeners do nothing
        if (!this.listeners[event]) {
            return;
        }

        // call all listeners of this event
        for (const listener of this.listeners[event]) {
            listener(...args);
        }
    }
}
