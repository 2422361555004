import {Observable} from "@foundation/utils/observable";

function generateRoutesGraph(routes, parent) {
    // add all routes to graph
    routes.forEach(route => {
        if (this.routes[route.id]) {
            throw Error(`A route with id ${route.id} is already defined. Remove duplicates to suppress the error.`);
        }
        this.routes[route.id] = route;

        // create links between routes
        this.graph[route.id] = this.graph[route.id] || {parent: null, children: []};
        if (route.children) {
            generateRoutesGraph.call(this, route.children, route);
        }
        if (parent) {
            this.graph[route.id].parent = parent.id;
            this.graph[parent.id].children.push(route.id);
        }
    });

    // root are routes without parent
    this.roots = routes.reduce((result, route) => {
        !this.graph[route.id].parent && result.push(route.id);
        return result;
    }, []);
}

function findRouteMatchingState(routes, state) {
    let route = routes.find(route => state.matches(route.state));
    if (route) {
        route = {...route, state: Object.freeze({...state})};
    }
    return route;
}

function createRoute(route) {
    const newRoute = {params: {}, ...route};
    const routeLink = this.graph[route.id];
    if (routeLink.params) {
        newRoute.params = {...newRoute.params, ...routeLink.params};
    }
    return newRoute;
}

function getMatchedRoutes(route) {
    const routes = this.getChildrenRoutes(route);
    if (!routes.length) {
        return [];
    }

    // store to listen
    const store = route?.store ? this.$store.manager.get(route.store) : this.defaultStore;
    if (!store && route?.store) {
        this.logger.warn(`Cannot find store ${route.store} used in route ${route.id}`);
        return [];
    }

    // find matching route
    const matchedRoute = findRouteMatchingState.call(this, routes, store.currentState);
    let matchedRoutes = [];
    if (matchedRoute) {
        matchedRoutes = [createRoute.call(this, matchedRoute), ...getMatchedRoutes.call(this, matchedRoute)];
    }

    return matchedRoutes;
}

function getMatchedStores() {
    const stores = this.matched.reduce((acc, x) => {
        if (x.store) {
            acc.add(x.store);
        }
        return acc;
    }, new Set());
    stores.delete(this.defaultStore.id);
    return stores;
}

function difference(a, b) {
    const diff = new Set(a);

    for (const elem of b) {
        diff.delete(elem);
    }

    return diff;
}

async function onTransition() {
    // get stores to unlisten
    const oldStores = getMatchedStores.call(this);

    // watch routes
    this.matched = getMatchedRoutes.call(this);

    // list of stores to watch for
    const newStores = getMatchedStores.call(this);

    // get stores to unlisten
    const storesToUnlisten = difference(oldStores, newStores);

    // get new stores to listen for
    const storesToListen = difference(newStores, oldStores);

    this.unListenStores(storesToUnlisten);
    this.listenStores(storesToListen);
}

export default class Router {
    #matched;

    #listenedStores;

    get matched() {
        return this.#matched;
    }

    set matched(value) {
        this.observable.emit("matched", value);
        this.#matched = value;
    }

    constructor({routes, store, $store, logger}) {
        this.$store = $store;
        this.defaultStore = store;
        this.logger = logger;
        this.#matched = [];

        // set of stores listened by the router
        this.#listenedStores = new Set();

        // create observable for information of things changed for the outside
        this.observable = new Observable();

        // precompute some structures
        this.routes = {};
        this.graph = {};
        generateRoutesGraph.call(this, routes);

        this.onTransition = onTransition.bind(this);
        this.defaultStore.service.onTransition(this.onTransition);
        this.onTransition();

        // when a store is removed, unlisten for transitions if it is in listened stores
        this.$store.manager.listen(
            "removed",
            store => this.#listenedStores.has(store.id) && store.service.off(this.onTransition)
        );

        // when a store is added, listen for transitions if this is an already listened store id
        this.$store.manager.listen(
            "added",
            store => this.#listenedStores.has(store.id) && store.service.onTransition(this.onTransition)
        );
    }

    // send a navigate to action to the machine of the route provided
    push(data) {
        const {name, params} = data;

        // find the route in graph
        const routeLink = this.graph[name];

        // do nothing if not found
        if (!routeLink) {
            return;
        }

        // the store of the route is the one described in its parent
        const parentRoute = this.routes[routeLink.parent];
        const storeId = parentRoute?.store || this.defaultStore.id;

        // indicate that this route needs to have its params merged for matched one
        routeLink.params = params;

        // send the navigate to transition to the store if existing
        this.$store.manager.get(storeId)?.service.send({
            type: "NAVIGATE_TO",
            data: {
                state: name,
                data: params,
            },
        });
    }

    getRoute(id) {
        return this.routes[id];
    }

    getChildrenRoutes(route) {
        const children = route ? this.graph[route.id].children : this.roots;
        return children.map(id => this.routes[id]);
    }

    getParentRoute(route) {
        const parentId = route ? this.graph[route.id].parent : null;
        return this.routes[parentId];
    }

    destroy() {
        const stores = getMatchedStores.call(this);
        stores.add(this.defaultStore.id);
        this.unListenStores(stores);
        this.defaultStore.service.off(this.onTransition);
        delete this.defaultStore;
    }

    listenStores(stores) {
        stores.forEach(storeId => {
            this.#listenedStores.add(storeId);
            this.$store.manager.get(storeId).service.onTransition(this.onTransition);
        });
    }

    unListenStores(stores) {
        // unlisten old matched stores
        // it is possible that the service or store is already destroyed once we want to unlisten so we need a security
        // check
        stores.forEach(storeId => {
            this.#listenedStores.delete(storeId);
            this.$store.manager.get(storeId)?.service?.off?.(this.onTransition);
        });
    }
}
