export default class BasePilot {
    constructor(options) {
        // register of ids and map to the element
        this.$ids = new Map();

        // a default item to focus if no one selected
        this.defaultItem = null;
        this.updateOptions(options);
    }

    updateOptions(options) {
        this.$options = options;
    }

    get(id) {
        return this.$ids.get(id);
    }

    getFirstItemId() {
        const iterator = this.$ids.keys();
        return iterator.next().value;
    }

    add(id, item) {
        // add element to the register of the cluster
        this.$ids.set(id, item);
    }

    remove(id) {
        // remove the element from the registers
        this.$ids.delete(id);
    }

    has(item) {
        return this.$ids.has(item);
    }

    navigateTo(focusedItemId, navigation, origin) {
        // do navigation on the cluster (next, previous, unfocus, etc);
        return this[navigation] ? this[navigation](focusedItemId) : null;
    }

    itemCount() {
        return this.$ids.size;
    }
}
