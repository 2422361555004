import {Machine, Interpreter} from "xstate";
import * as actionTypes from "xstate/es/actionTypes";
import {toSCXMLEvent} from "xstate/es/utils";
import makeStoreManager from "./manager";

// FIXME to remove once correction provided for xstate
const DEFAULT_SPAWN_OPTIONS = {sync: false, autoForward: false};

export const makeStore = dependencies => {
    // get needed dependencies
    const {history: HISTORY, logger: LOGGER, Vue, settings} = dependencies;

    // generate class to create a store manager instance
    const StoreManager = makeStoreManager(dependencies);

    // the manager of machines
    const manager = new StoreManager();

    // optimized list of machines to inspect via xstate inspector from settings
    const machinesToInspect = new Set(settings.xstateInspector?.machines?.view || []);

    // custom interpreter to register services when created and spawned by machines themselves
    class CustomInterpreter extends Interpreter {
        constructor(machine, options) {
            // machine id
            const machineId = options?.id || machine.id;
            let newMachine;

            // check if there is not a machine with same id already existing
            if (manager.get(machineId)) {
                throw Error(`A machine with ${machineId} already exists`);
            }

            // copy machine options and mark the machine to inspected if configured inside the settings
            const newMachineOptions = {...options, devTools: machinesToInspect.has(machineId)};

            // this context for the machine
            const thisContext = {
                pushHistory(data, options) {
                    // FIXME avoid duplicate states in the history when setting
                    // a state from the history creating a new push
                    const source = options?.id || machineId;
                    if (options?.noDuplicates) {
                        const current = HISTORY.fetch();
                        if (data?.state === current?.data?.state && current?.source === machineId) {
                            return;
                        }
                    }

                    // push in browser history
                    HISTORY.push({source, data});
                },
                replaceHistory(data) {
                    HISTORY.replace({source: machineId, data});
                },
                spawn: (...args) => this.spawn(...args),
            };

            // wrap options with action to push event in history
            if (machine.options.actions) {
                const actions = {...machine.options.actions};

                for (const key of Object.keys(actions)) {
                    // bind the action with some methods related to the machine
                    const action = actions[key];
                    if (typeof action === "function") {
                        actions[key] = action.bind(thisContext);
                    }
                }

                // update machine actions with the ones binded
                // IMPORTANT
                // the withConfig will remove options in the new machine if they do not match the interface
                // defined by xstate. To keep access to getters that we add ourselves and to not change other things
                // in the code, we keep the getters reference and set it in the new machine
                const {getters} = machine.options;
                newMachine = machine.withConfig({actions});
                newMachine.options.getters = getters;
            } else {
                newMachine = machine;
            }

            // create an observable context to link state machine to vue reactivity system
            const context = Vue.observable((machine.context && {...machine.context}) || {});

            // change the context of the machine and create the service with parent's method
            newMachine = newMachine.withContext(context);
            super(newMachine, newMachineOptions);

            // register instance into the manager
            manager.add(this);
        }

        // FIXME hack to be able to spawn a new machine from its config with our custom interpreter
        // see https://github.com/davidkpiano/xstate/issues/704 for advances of the issue on xstate
        spawnMachine(machine, options) {
            // the machine is created correctly with our custom interpreter in this way
            const childService = new this.constructor(machine, {
                ...this.options, // inherit options from this interpreter
                parent: this,
                id: options.id || machine.id,
            });

            const resolvedOptions = {
                ...DEFAULT_SPAWN_OPTIONS,
                ...options,
            };

            if (resolvedOptions.sync) {
                childService.onTransition(state => {
                    this.send(actionTypes.update, {
                        state,
                        id: childService.id,
                    });
                });
            }

            const actor = childService;

            this.children.set(childService.id, actor);

            if (resolvedOptions.autoForward) {
                this.forwardTo.add(childService.id);
            }

            childService
                .onDone(doneEvent => {
                    this.removeChild(childService.id);
                    this.send(toSCXMLEvent(doneEvent, {origin: childService.id}));
                })
                .start();

            return actor;
        }
    }

    // when a new machine is added to the manager, it has been created and we
    // retrieve associated store letting us register things
    manager.listen("added", store => {
        // local logger for this store
        const logger = LOGGER.labels(`Store ${store.service.id}`);

        // set initial state as current one in store
        try {
            store.currentState = store.service.machine.initialState;
        } catch (e) {
            logger.error(e);
            throw e;
        }

        store.tracker.updateContext(store.currentState);

        // add the state on the context when it changes
        store.service.onTransition(async state => {
            // indicate event that transtion the machine
            if (settings.global.useDeveloperMode) {
                logger.info(`event: ${state.event.type} - ${JSON.stringify(state.value)}`);
            }

            // keep current state into the store
            store.currentState = state;

            // update the getter state contexts
            store.tracker.updateContext(state);
        });

        // each time the history changes from the browser history, send an event with transition the name in the
        // state and the payload registered
        store.historyListener = (historyEvent, prevState) => {
            store.service.send("NAVIGATE_TO", {data: historyEvent.data, prevData: prevState.data});
        };
        HISTORY.listen(store.id, store.historyListener);

        // when the service is stopped, remove it from the manager
        store.service.onStop(() => {
            manager.remove(store.service);
        });
    });

    // when a machine is removed
    manager.listen("removed", store => {
        // stop listening for changes
        HISTORY.unlisten(store.id, store.historyListener);
    });

    // use the machine
    const useMachine = (config, options, interpreterOptions) => {
        // create the machine from the config
        const machine = Machine(config, options);

        // create the service from the machine
        const service = new CustomInterpreter(machine, interpreterOptions);

        // the service has been created and registered into the manager and we return it
        return manager.get(service.machine.id);
    };

    // install plugin for Vue
    const install = (Vue, options) => {
        Vue.prototype.$store = $store;
    };

    // interface for machine creation, to use the same xstate everywhere
    const createMachine = (config, options) => {
        if (!config.context) {
            config.context = {};
        }
        return Machine(config, options);
    };

    // create store and return
    const $store = {
        install,
        useMachine,
        createMachine,
        manager,
    };

    return $store;
};
