export const loadScript = async ({url}) =>
    // load controller from the url and create it from the exposed factory
    new Promise((resolve, reject) => {
        // create script element to load data
        const script = document.createElement("script");
        script.async = true;

        // once script loaded
        script.addEventListener("load", resolve);

        // in case of error
        script.addEventListener("error", () => {
            reject(new Error(`Error loading ${url}`));
        });

        // load url
        script.src = `${url}?v=${process.env.APP_VERSION}`;
        document.head.appendChild(script);
    });
