import BasePilot from "./base";

const random = keys =>
    // eslint-disable-next-line no-bitwise
    keys[(keys.length * Math.random()) << 0];
export default class RandomPilot extends BasePilot {
    next() {
        return random([...Array.from(this.$ids.keys()), null]);
    }

    previous() {
        return random([...Array.from(this.$ids.keys()), null]);
    }
}
