import BasePilot from "./base";

/**
 * Navigation Options:
 *   default: vertical/horizontal navigation based on center of previous Active item
 *  'useMeridian=true' means vertical navigation based on center of Active initial item
 *  'useLeftAnchor=true' means vertical navigation based on left position of items
 *  'edge' Object with:
 *            - keys: "north", "south", "east" or "west"
 *            - value "handover" to delegate the navigation on parent when the specified edge is reach
 *
 */

// we consider that elements in a ine can have some imprecisions and consider in the line all
// those distances are below this small value
const EPSILON = 2;

export default class CardinalPilot extends BasePilot {
    #meridian;

    constructor(options) {
        super(options);
        this.#meridian = null;
    }

    #getXAnchor(coord) {
        return this.$options.useLeftAnchor ? coord.left : (coord.left + coord.right) / 2;
    }

    #up_down(coordOrigin, filtering) {
        let destinationKey;

        // compute the meridian of the point if necessary
        if (this.#meridian === null && this.$options.useMeridian) {
            this.#meridian = this.#getXAnchor(coordOrigin);
        }

        // compute coordinates of the original points
        const originX = this.#meridian !== null ? this.#meridian : this.#getXAnchor(coordOrigin);
        const originY = (coordOrigin.bottom + coordOrigin.top) / 2;

        // compute distances vertically to get the minimal distance following this axis
        let minYDistance;
        const distances = {};
        let items = this.$ids.entries();
        for (const [key, value] of items) {
            const coordDestination = value.$el.getBoundingClientRect();
            if (filtering(coordOrigin, coordDestination)) {
                const distance = Math.abs(coordDestination.top - originY);
                minYDistance = minYDistance !== undefined ? Math.min(minYDistance, distance) : distance;
                distances[key] = distance;
            }
        }

        // reset minimal dstance
        let minXDistance;

        // loop on items and determine the closest one horizontally
        items = this.$ids.entries();
        for (const [key, value] of items) {
            if (distances[key] === undefined || Math.abs(distances[key] - minYDistance) >= EPSILON) {
                // filter elements for distances that are defined and in the epsilon ranges so that we select
                // only elements acting as a line above/below the origin element
            } else {
                // compute horizontal distances for elements on the line to be able to determine the one closest
                // horizontally to the origin element
                const coordDestination = value.$el.getBoundingClientRect();
                if (coordDestination.left <= coordOrigin.left && coordOrigin.right <= coordDestination.right) {
                    // move to the big item above/bellow current item
                    minXDistance = 0;
                    destinationKey = key;
                    break;
                }
                const distance = Math.abs(this.#getXAnchor(coordDestination) - originX);
                if (minXDistance === undefined || distance < minXDistance) {
                    minXDistance = distance;
                    destinationKey = key;
                }
            }
        }
        return destinationKey;
    }

    #left_right(coordOrigin, filtering) {
        let minDistance;
        let destinationKey;

        // loop on elements registered
        for (const [key, value] of this.$ids) {
            const coordDestination = value.$el.getBoundingClientRect();
            // filter elements according to the criteria for the ongoing action
            if (filtering(coordOrigin, coordDestination)) {
                const distance = Math.hypot(
                    coordDestination.left - coordOrigin.left,
                    coordDestination.top - coordOrigin.top
                );

                // determine closest element
                if (minDistance === undefined || distance < minDistance) {
                    minDistance = distance;
                    destinationKey = key;
                }
            }
        }

        // reset the meridian when the user navigates on the current line and that there is a new element
        if (destinationKey) {
            this.#meridian = null;
        }
        return destinationKey;
    }

    #navigate(originKey, heading) {
        // find the associated element
        const originItem = this.get(originKey);

        // default detsination key
        let destinationKey = originKey;

        // coordinates of the original element
        const coordOrigin = originItem.$el.getBoundingClientRect();

        // the different cases of actions handled
        switch (heading) {
            case "north":
                destinationKey =
                    this.#up_down(
                        coordOrigin,
                        // keep elements above the line for the compuation
                        (coordOrigin, coordDestination) => coordDestination.bottom < coordOrigin.top
                    ) || destinationKey;
                break;
            case "south":
                destinationKey =
                    this.#up_down(
                        coordOrigin,
                        // keep elements under the line for the computations
                        (coordOrigin, coordDestination) => coordDestination.top > coordOrigin.bottom
                    ) || destinationKey;
                break;
            case "east":
                if (this.$options.grid) {
                    destinationKey = originItem.$el.getAttribute("nextItem");
                    this.#meridian = null;
                } else {
                    destinationKey =
                        this.#left_right(
                            coordOrigin,
                            // keep elements intercepting the line
                            (coordOrigin, coordDestination) =>
                                coordDestination.left > coordOrigin.left &&
                                coordDestination.top >= coordOrigin.top &&
                                coordDestination.top <= coordOrigin.bottom
                        ) || destinationKey;
                }
                break;
            case "west":
                if (this.$options.grid) {
                    destinationKey = originItem.$el.getAttribute("prevItem");
                    this.#meridian = null;
                } else {
                    destinationKey =
                        this.#left_right(
                            coordOrigin,
                            // keep elements intercepting the line
                            (coordOrigin, coordDestination) =>
                                coordDestination.left < coordOrigin.left &&
                                coordDestination.top >= coordOrigin.top &&
                                coordDestination.top <= coordOrigin.bottom
                        ) || destinationKey;
                }
                break;
            default:
                break;
        }
        if (destinationKey === originKey && this.$options.edge && this.$options.edge[heading] === "handover") {
            // if focus is at the cluster edge and the option at the edge is handover
            // return so that focus control is handed over to the parent cluster
            destinationKey = undefined;
        }
        return destinationKey;
    }

    north(originKey) {
        return this.#navigate(originKey, "north");
    }

    east(originKey) {
        return this.#navigate(originKey, "east");
    }

    west(originKey) {
        return this.#navigate(originKey, "west");
    }

    south(originKey) {
        return this.#navigate(originKey, "south");
    }
}
