// private registry property
/* eslint-disable symbol-description */
const REGISTRY_PROPERTY = Symbol();
const CALL_PROPERTY = Symbol();
const LOGGER_PROPERTY = Symbol();
/* eslint-enable symbol-description */

// given ["a", "b", "c"] returns {
//   object: ["a", "b"],
//   objectPath: "a.b",
//   method: "c"
// }
function getObjectAndMethod(path) {
    const object = path.slice(0, -1);
    return {
        object,
        objectPath: object.join("."),
        method: path[path.length - 1],
    };
}

export {getObjectAndMethod, REGISTRY_PROPERTY, CALL_PROPERTY, LOGGER_PROPERTY};
