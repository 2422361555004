import StateTracker from "./StateTracker";

export default class Store {
    constructor({id, service, Vue}) {
        this.id = id;
        this.service = service;

        // null current state
        this.currentState = null;

        // state dependencies tracker
        this.tracker = new StateTracker({context: Vue.observable({}), $delete: Vue.delete, $set: Vue.set});
    }

    start() {
        return this.service.start();
    }

    stop() {
        return this.service.stop();
    }
}
