import {Observable} from "@foundation/utils/observable";

const ALLOWED_EVENTS = ["created", "started", "activated", "deactivated", "stopped", "destroyed", "shown", "hidden"];

export default function makeAppClass({logger, Service}) {
    class App extends Service {
        // logger for applications
        static logger = logger.labels("App");

        static observable = new Observable({
            events: [...ALLOWED_EVENTS, ...ALLOWED_EVENTS.map(item => `pre:${item}`)],
        });

        static TRANSITIONS = {
            ...super.TRANSITIONS,
            show: new Set(["started", "activated", "deactivated"]),
            hide: new Set(["started", "activated", "deactivated"]),
        };

        constructor(...args) {
            super(...args);
            this.isVisible = true;
            // create node used as entrypoint for an application
            this.node = document.createElement("div");
            this.node.setAttribute("id", `app-${this.id}`);
        }

        async show(...args) {
            if (!this.constructor.TRANSITIONS.show.has(this.state) && !this.isVisible) {
                this.logger.warn(`Cannot go from state ${this.state} to show`);
                return;
            }
            this.constructor.observable.emit("pre:shown", this);
            await this.controller.show?.(this, ...args);
            this.logger.info(`Moving from state ${this.state} to shown`);
            this.isVisible = true;
            this.constructor.observable.emit("shown", this);
            this.emit("shown", this);
        }

        async hide(...args) {
            if (!this.constructor.TRANSITIONS.hide.has(this.state) && this.isVisible) {
                this.logger.warn(`Cannot go from state ${this.state} to hidden`);
                return;
            }
            this.constructor.observable.emit("pre:hidden", this);
            await this.controller.hide?.(this, ...args);
            this.logger.info(`Moving from state ${this.state} to hide`);
            this.isVisible = false;
            this.constructor.observable.emit("hidden", this);
            this.emit("hidden", this);
        }
    }
    return App;
}
