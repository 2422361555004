import url from "url";
import loaderConfig from "@settings/loaderConfig.json";

const {defaultConfigId} = loaderConfig;

const logInfo = message => {
    const timestamp = new Date().toISOString();
    const str = `${timestamp} \u001b[32mInfo\u001b[39m ConfigManager ${message}`;
    // eslint-disable-next-line no-console
    console.log(str);
};

export default class ConfigManager {
    constructor(configID) {
        const storedConfigId = localStorage.getItem("NARC_CONFIG_ID");
        const params = url.parse(window.location.href, true).query;
        this.configID = configID || params.configID || storedConfigId || defaultConfigId;
        if (this.configID !== storedConfigId) {
            localStorage.setItem("NARC_CONFIG_ID", this.configID);
        }
        this.cache = {};
    }

    getConfigId() {
        return this.configID;
    }

    async loadConfig(filePath) {
        let configPath;
        let config = this.cache[filePath];
        if (!config) {
            const configPathArr = `${this.configID}/${filePath}`.split("/");

            while (configPathArr.length) {
                try {
                    configPath = configPathArr.join("/");
                    config = await import(
                        /* webpackMode: "lazy-once", webpackChunkName: "config-app" */ `@settings/configurationApp/${configPath}`
                    );
                    logInfo(`load ${configPath}`);
                    break;
                } catch (e) {
                    configPathArr.splice(-2, 1); // remove last folder on path
                }
            }
            if (!config) {
                throw Error(`Fail to load config ${filePath} for configID ${this.configID}`);
            }
            config = config.default;
            this.cache[filePath] = config;
        }

        return config || {};
    }
}
