let currentFocusedItem = null;
let watchdogLastItem = null;
let watchdogHandler = null;

export default {
    props: {
        id: {
            type: String,
            default: null,
        },
        default: {
            type: Boolean,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            active: false,
        };
    },
    watch: {
        active(value) {
            this.$emit(value ? "focus" : "blur", this.customId);
        },
        disabled(value) {
            value && this.$emit("disable", this.customId);
        },
    },
    computed: {
        customId() {
            // Hack to get an id, _uid was set by vuejs
            // eslint-disable-next-line no-underscore-dangle
            return this.id || this._uid;
        },
    },
    created() {
        // create custom logger for a focus element (cluster or item) that can be used to always have a good label on
        // logs
        this.$focusLogger = this.$logger.labels(`[${this.customId}]`);
    },
    mounted() {
        // register the cluster inside the parent
        if (!this.isInsideLeaf) {
            this.cluster && this.cluster.register(this, this.customId);
        } else {
            this.$logger.warn(
                `Detected registration of cluster ${this.customId} inside an item. The cluster is now a root.`
            );
        }
    },
    destroyed() {
        // unregister the cluster
        this.cluster && this.cluster.unregister(this, this.customId);
    },
    inject: {
        cluster: {
            default: null,
        },
    },
    methods: {
        isItemFocusable(item) {
            return !item.disabled;
        },
        focusItem(item) {
            // store previously focused item
            const previousFocusedItem = currentFocusedItem;

            // focus the element in the DOM
            item.$el.focus({preventScroll: true});

            // set new current focused item
            currentFocusedItem = item;

            // indicate previously focused item that it is not active to be able to detect blur
            previousFocusedItem && previousFocusedItem !== item && (previousFocusedItem.active = false);

            if (!this.$settings?.global?.focusWatchdog?.disable && this.$features?.input === "remote") {
                // watchdog to take focus back when lose it
                // watchdog register last known focusable
                if (
                    // hack, _provided come from vuejs
                    // eslint-disable-next-line no-underscore-dangle
                    currentFocusedItem._provided.isInsideLeaf &&
                    currentFocusedItem.$el === document.activeElement &&
                    currentFocusedItem.cluster.active
                ) {
                    watchdogLastItem = currentFocusedItem;
                }

                clearInterval(watchdogHandler);

                // set watchdog interval with global.focusWatchdog.interval
                const watchdogInterval = this.$settings?.global?.focusWatchdog?.interval
                    ? this.$settings?.global?.focusWatchdog?.interval
                    : 30000;

                watchdogHandler = setInterval(() => {
                    const badFocusNotification = () => {
                        // disable watchdog notification only global.focusWatchdog.disableNotification
                        if (!this.$settings?.global?.focusWatchdog?.disableNotification) {
                            this.$bus?.notifications.push({
                                title: "TECHNICAL ERROR",
                                description: "FOCUS ERROR",
                                type: "warning",
                                duration: 2000,
                            });
                        }
                    };
                    const watchDogLogger = this.$focusLogger.labels("WATCHDOG");
                    // if there is last known focusable item try to get focus back to that item
                    if (watchdogLastItem) {
                        if (
                            watchdogLastItem.$el !== document.activeElement ||
                            document.activeElement === document.body
                        ) {
                            // if last item exists in DOM focus to item otherwise focus on item cluster
                            if (watchdogLastItem.$el && document.getElementById(watchdogLastItem.$el.id)) {
                                watchDogLogger.info(
                                    `focus bad: ${document.activeElement.id} => ${watchdogLastItem.$el.id}`
                                );
                                badFocusNotification();
                                watchdogLastItem.cluster.focus();
                                watchdogLastItem.focus();
                            } else {
                                watchDogLogger.info(
                                    `focus bad: ${document.activeElement.id || "body"} => reset last known`
                                );
                                badFocusNotification();
                                watchdogLastItem.cluster.reset();
                                watchdogLastItem.cluster.focus();
                            }
                        } else {
                            watchDogLogger.info(`focus is good: ${document.activeElement.id}`);
                        }
                    }
                    // if not last known focusable item reset from cluster
                    if (!document.activeElement || document.activeElement === document.body) {
                        watchDogLogger.info(`focus bad: ${document.activeElement.id || "body"} => reset cluster`);
                        badFocusNotification();
                        this.cluster.reset();
                        this.cluster.focus();
                    }
                }, watchdogInterval);
            }
        },
    },
};
