const genId = () => `${Date.now()}#${Math.round(Math.random() * 100000)}`;

export default class History {
    #logger;

    #handlers;

    #popStateHandler;

    constructor({logger, settings}) {
        // History log are under useDeveloperMode flag
        this.#logger = settings.global?.useDeveloperMode ? logger?.labels("History") : undefined;
        this.stack = [];
        this.#handlers = [];
        this.#popStateHandler = this.onPopState.bind(this);
        window.addEventListener("popstate", this.#popStateHandler);
    }

    stateToString(state) {
        return state ? `${state.source}@${state.data?.state || state.data?.current || ""}` : "";
    }

    push(state) {
        this.stack.push(state);
        this.#logger?.info(`push, new stack:${this.stack.map(this.stateToString)}`);
        // push state id only, data is kept in the application
        window.history.pushState(
            {
                // build unique id used to map browser history state with state stored in the app
                id: genId(),
                source: state.source,
            },
            state.source
        );
    }

    replace(state) {
        this.stack.pop();
        this.stack.push(state);
        this.#logger?.info(`replace, new stack:${this.stack.map(this.stateToString)}`);
        window.history.replaceState(
            {
                id: genId(),
                source: state.source,
            },
            state.source
        );
    }

    fetch() {
        // return the last one
        return this.stack[this.stack.length - 1];
    }

    call(state = {}, prevState = {}) {
        const eventSource = state.source;
        this.#handlers.forEach(handler => {
            if (!handler.source || handler.source === "any" || handler.source === eventSource) {
                handler.callback(state, prevState);
            }
        });
    }

    listen(targetSource, callback) {
        this.#handlers.push({
            source: targetSource,
            callback,
        });
    }

    unlisten(targetSource, callback) {
        const index = this.#handlers.findIndex(item => item.source === targetSource && item.callback === callback);
        this.#handlers.splice(index, 1);
    }

    onPopState(event) {
        // this is the browser state
        const {state} = event;

        if (!state) {
            throw Error("Popped history state not defined");
        }

        // pop the internal state
        let prevState;
        if (this.stack.length > 1) {
            prevState = this.stack.pop();
        }

        // send the state to use to handlers
        const newState = this.fetch();
        this.#logger?.info(`onPopState  newState ${this.stateToString(newState)}`);
        if (!newState) {
            return;
        }
        // FIXME to remove, we should only send events to the store that is working
        if (prevState && newState.source !== prevState.source) {
            // notify the leaving service (the leaving state machine, application ...)
            this.call({source: prevState.source, data: {}}, prevState);
        }
        this.call(newState, prevState);
    }

    back() {
        if (window.history.state) {
            window.history.back();
        }
    }

    // used to search for an entry via a callback function determining the matching state
    find(callback) {
        // find the index of the first matching state
        const index = this.stack.findIndex(callback);
        let result;

        if (index !== -1) {
            // If found return the state and its index in the stack
            result = {
                state: this.stack[index],
                index,
            };
        }

        return result;
    }

    // similar to go of the browser history API, given an index in our history stack, will set the history
    // to this state and replace state for the browser API
    go(index) {
        const targetIndex = index < 0 ? this.stack.length - 1 + index : index;

        // if element not present, do nothing
        const state = this.stack[targetIndex];
        if (!state) {
            return;
        }
        const leavingState = this.fetch();
        // FIXME to remove we should only send events to the store that will be working
        // has been introduced to manage back on pincode app
        if (leavingState.source !== state.source) {
            // notify the leaving service (the leaving state machine, application ...)
            this.call({source: leavingState.source, data: {}}, leavingState);
        }

        // truncate the history to the given index more one to allow pop of the state when going back
        this.stack.splice(targetIndex + 1);

        // replace state in browser history
        window.history.replaceState(
            {
                ...state,
            },
            state.source
        );

        this.call(state, leavingState);
    }

    forward() {
        window.history.forward();
    }

    // clear our internal history
    clear() {
        this.stack = [];
    }

    // Remove last state pushed without any actions
    removeLastState() {
        this.stack.pop();
        window.history.forward();
    }

    destroy() {
        window.removeEventListener("popstate", this.#popStateHandler);
        window.onbeforeunload = undefined;
    }
}
