import RandomPilot from "./random";
import LinearPilot from "./linear";
import CardinalPilot from "./cardinal";
import CartesianPilot from "./cartesian";
import GridPilot from "./grid";

export const makePilot = (type, options) => {
    let pilot;
    switch (type) {
        case "random":
            pilot = new RandomPilot();
            break;
        case "linear":
            pilot = new LinearPilot(options);
            break;
        case "cardinal":
            pilot = new CardinalPilot(options);
            break;
        case "cartesian":
            pilot = new CartesianPilot(options);
            break;
        case "grid":
            pilot = new GridPilot(options);
            break;
        default:
            break;
    }
    return pilot;
};
