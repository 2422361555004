import url from "url";
import querystring from "querystring";

export default class RedirectionManager {
    #deepLink;

    #storage;

    #currentRedirection;

    constructor({logger}) {
        this.logger = logger;
        this.create();
    }

    create() {
        const parsedUrl = url.parse(window.location.href, true);
        this.url = parsedUrl;
        this.#deepLink = parsedUrl.query.deepLink;

        // clear the URL for the deep link
        delete parsedUrl.query.deepLink;
        parsedUrl.search = `?${querystring.stringify(parsedUrl.query)}`;
        window.history.replaceState(window.history.state, null, url.format(parsedUrl));

        this.#storage = {};
    }

    async updateRedirection(redirections) {
        // loop on redirections and find the one matching a redirection condition
        this.#currentRedirection = redirections.find(redirection => {
            let matches = false;
            if (typeof redirection.matches === "function") {
                // the redirection entry indicates itself if it is the matching redirection or not
                matches = redirection.matches(this);
            } else if (typeof redirection.matches === "string") {
                matches = this.#deepLink === redirection.matches;
            }
            return matches;
        });

        // if the redirection has defined hooks, execute them
        if (this.#currentRedirection) {
            this.logger.info(`Coming from redirection ${this.#currentRedirection.id}`);
            const {hooks} = this.#currentRedirection;
            if (hooks?.length) {
                for (const hook of hooks) {
                    if (typeof hook === "function") {
                        await hook(this);
                    }
                }
            }
        } else {
            this.logger.info("Not coming from a registered redirection");
        }
    }

    matches(id) {
        let ids = id;
        if (typeof id === "string") {
            ids = [id];
        }
        return ids.reduce((value, id) => value || this.#currentRedirection?.id === id, false);
    }

    store(key, value) {
        this.#storage[key] = value;
    }

    get(key, value) {
        return this.#storage[key];
    }

    reset() {
        this.#storage = {};
        this.#currentRedirection = undefined;
        this.#deepLink = undefined;
    }
}
