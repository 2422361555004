import loadUMD from "./loadUMD";

export default class LibraryManager {
    constructor() {
        this.register = {};
    }

    get(id) {
        return this.register[id];
    }

    add(library) {
        this.register[library.id] = library;
    }

    remove(id) {
        delete this.register[id];
    }

    async needs(id, contents) {
        // get the library config
        const library = this.get(id);

        // check format
        if (!library.source) {
            throw Error(`Missing source data for library ${id}`);
        }

        await loadUMD(library);
    }
}
