import {Pather} from "./pather";
import {Registry} from "./Registry";
import {getObjectAndMethod, CALL_PROPERTY} from "./busHelper";

class SharedBus {
    constructor({logger}) {
        // registry of objects paths, definitions etc
        const registry = new Registry({logger});

        // handle an API call through paths
        const pathHandler = data => {
            // called method and object
            const {method, object, objectPath} = getObjectAndMethod(data.path);

            // get the function to call on registry, and do nothing if not defined
            const registryMethod = registry[method];
            if (registryMethod) {
                return registryMethod.call(registry, object, objectPath, data.args);
            }

            // the method is not in registry, try to call the method defined in the object
            return registry[CALL_PROPERTY](objectPath, method, data.args);
        };

        // create pather instance
        // eslint-disable-next-line no-constructor-return
        return new Pather(this, pathHandler);
    }
}

export const makeSharedBus = dependencies => new SharedBus(dependencies);
