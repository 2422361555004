import BasePilot from "./base";

const SIGMA = 2;
export default class CartesianPilot extends BasePilot {
    #meridian;

    #parallel;

    constructor(options) {
        super(options);
        this.#meridian = null;
        this.#parallel = null;
    }

    #getOriginCoord(originKey) {
        // find the origin element
        const originItem = this.get(originKey);
        return originItem.$el.getBoundingClientRect();
    }

    #navigate(originKey, heading) {
        // getting started
        let destinationKey = originKey;
        let minDistance = 9999;
        const coordOrigin = this.#getOriginCoord(originKey);

        // calculate the meridian or the parallel if needed
        this.#setNavigationAxis(heading, coordOrigin);

        const navigationFilter = this.#setNavigationFilter(heading);

        for (const [key, value] of this.$ids) {
            const coordDestination = navigationFilter(coordOrigin, value.$el.getBoundingClientRect());
            if (coordDestination !== null) {
                const distance = Math.hypot(
                    coordDestination.left - coordOrigin.left,
                    coordDestination.top - coordOrigin.top
                );
                if (distance < minDistance) {
                    minDistance = distance;
                    destinationKey = key;
                }
            }
        }

        if (destinationKey === originKey && this.$options.edge && this.$options.edge[heading] === "handover") {
            // if focus is at the cluster edge and the option at the edge is handover
            // return so that focus control is handed over to the parent cluster
            destinationKey = undefined;
        }
        return destinationKey;
    }

    #setNavigationAxis(heading, coordOrigin) {
        switch (heading) {
            case "north":
            case "south":
                if (this.$options.useParallel && this.#parallel !== null) {
                    this.#parallel = null;
                }
                if (this.$options.useMeridian && this.#meridian === null) {
                    this.#meridian = (coordOrigin.left + coordOrigin.right) / 2;
                }
                break;
            case "east":
            case "west":
                if (this.$options.useMeridian && this.#meridian !== null) {
                    this.#meridian = null;
                }
                if (this.$options.useParallel && this.#parallel === null) {
                    this.#parallel = (coordOrigin.top + coordOrigin.bottom) / 2;
                }
                break;
            default:
                break;
        }
    }

    #setNavigationFilter(heading) {
        let filter;
        switch (heading) {
            case "north":
                if (this.$options.useMeridian) {
                    filter = (coordOrigin, coordDestination) =>
                        coordDestination.left <= this.#meridian &&
                        coordDestination.right >= this.#meridian &&
                        coordDestination.bottom - SIGMA <= coordOrigin.top
                            ? coordDestination
                            : null;
                } else {
                    filter = (coordOrigin, coordDestination) =>
                        coordDestination.bottom - SIGMA <= coordOrigin.top ? coordDestination : null;
                }
                break;
            case "south":
                if (this.$options.useMeridian) {
                    filter = (coordOrigin, coordDestination) =>
                        coordDestination.top + SIGMA >= coordOrigin.bottom &&
                        coordDestination.left <= this.#meridian &&
                        coordDestination.right >= this.#meridian
                            ? coordDestination
                            : null;
                } else {
                    filter = (coordOrigin, coordDestination) =>
                        coordDestination.top + SIGMA >= coordOrigin.bottom ? coordDestination : null;
                }
                break;
            case "east":
                if (this.$options.useParallel) {
                    filter = (coordOrigin, coordDestination) =>
                        coordDestination.left + SIGMA >= coordOrigin.right &&
                        coordDestination.top <= this.#parallel &&
                        coordDestination.bottom >= this.#parallel
                            ? coordDestination
                            : null;
                } else {
                    filter = (coordOrigin, coordDestination) =>
                        coordDestination.left + SIGMA >= coordOrigin.right ? coordDestination : null;
                }
                break;
            case "west":
                if (this.$options.useParallel) {
                    filter = (coordOrigin, coordDestination) =>
                        coordDestination.right - SIGMA <= coordOrigin.left &&
                        coordDestination.top <= this.#parallel &&
                        coordDestination.bottom >= this.#parallel
                            ? coordDestination
                            : null;
                } else {
                    filter = (coordOrigin, coordDestination) =>
                        coordDestination.right - SIGMA <= coordOrigin.left ? coordDestination : null;
                }
                break;
            default:
                break;
        }
        return filter;
    }

    north(originKey) {
        return this.#navigate(originKey, "north");
    }

    east(originKey) {
        return this.#navigate(originKey, "east");
    }

    south(originKey) {
        return this.#navigate(originKey, "south");
    }

    west(originKey) {
        return this.#navigate(originKey, "west");
    }
}
