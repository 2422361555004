import {makeStore} from "@foundation/utils/store";

const injectStateInspector = container => {
    // get settings to decide to add debug code or not
    const {settings} = container;

    // register an inspector for state machines, only when in dev mode and machines to inspect are defined
    const inspectorMachines = settings.xstateInspector?.machines;
    if (settings.global.useDeveloperMode && (inspectorMachines?.view?.length || inspectorMachines?.apis?.length)) {
        // To remove eslint build issue on CI
        // eslint-disable-next-line import/no-extraneous-dependencies
        const {inspect} = require("@xstate/inspect");

        inspect({
            // options
            // FIXME once the UI will be released and accessible, use the local version, instead of the one online
            // url: 'https://statecharts.io/inspect', // (default)
            url: settings.xstateInspector?.ui?.url,
            iframe: false, // open in new window
        });
    }
};

export default container => {
    container.declare("$store", ({history, logger, settings}) => {
        const Vue = require("vue").default;
        // inject code for inspecting state machines if necessary
        if (process.env.NODE_ENV !== "production") {
            injectStateInspector(container);
        }

        // create store
        const $store = makeStore({history, logger, settings, Vue});
        Vue.use($store);
        return $store;
    });
};
