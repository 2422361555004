import Store from "./manager/Store";

export default function makeStoreManager({Vue}) {
    return class StoreManager {
        #register;

        #listeners;

        constructor() {
            this.#register = {};
            this.#listeners = {};
        }

        // call all listeners when an event is emitted
        emit(event, ...args) {
            const listeners = this.#listeners[event];
            if (!listeners) {
                return;
            }
            for (const listener of listeners) {
                listener(...args);
            }
        }

        // listen for some events
        listen(event, listener) {
            this.#listeners[event] = this.#listeners[event] || new Set();
            this.#listeners[event].add(listener);
        }

        // unlisten for some events
        unlisten(event, listener) {
            if (!this.#listeners[event]) {
                return;
            }
            this.#listeners[event].delete(listener);
            if (!this.#listeners[event].size) {
                delete this.#listeners[event];
            }
        }

        get(id) {
            return this.#register[id];
        }

        add(service) {
            const store = new Store({id: service.id, service, Vue});
            this.#register[service.id] = store;
            this.emit("added", store);
        }

        remove(service) {
            const store = this.#register[service.id];
            this.emit("removed", store);
            delete this.#register[service.id];
        }
    };
}
