import IoC from "@foundation/utils/ioc";
import makeServiceManager from "@foundation/utils/serviceManager";
import {initRedirectionManager} from "@helpers";

const registerProviders = async (container, providers, options) => {
    // const providerKeyList = Object.keys(providers);
    for (const key of Object.keys(providers)) {
        const provider = providers[key];
        // pass container to register dependencies
        await provider(container, options && options[key]);
    }
};

const startServices = async ({bus, settings}) => {
    // get services to start from configuration
    const servicesId = settings.services.initial || [];

    // create each services in parallel
    const services = await Promise.all(servicesId.map(serviceId => bus.serviceManager.create({id: serviceId})));

    // now start and activate each service in order
    for (const service of services) {
        await service.start();
        await service.activate();
    }
};

export default async ({libraryManager, configManager, options}) => {
    // create new IoC container for dependency management
    const container = new IoC();

    // create the redirection manager before loading settings providers to be able to rewrite URL due to redirection
    // before the settings override
    await initRedirectionManager(container);

    // register library manager
    container.declare("libraryManager", () => libraryManager);

    // create and register config manager
    container.declare("configManager", () => configManager);

    // loads services
    const providers = require("@providers");

    // load providers locally and from foundation
    await registerProviders(container, providers, options);

    // create a service manager
    makeServiceManager(container);

    // start services defined
    await startServices(container);

    // Merge Sava helpers with exported foundation helpers
    Object.assign(window.foundation.helpers, window.sava.helpers);
};
