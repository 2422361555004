import {commonServices} from "@services";

export default function serviceManager(dependencies) {
    const services = {};
    const {bus, Service} = dependencies;

    // register method to create a service from the ones registered in the expected path
    bus.serviceManager.create.register(async ({id, context}) => {
        const ControllerClass = (
            commonServices.indexOf(id) === -1
                ? await import(/* webpackMode: "lazy-once", webpackChunkName: "services" */ `@services/${id}`)
                : await import(
                      /* webpackMode: "lazy-once", webpackChunkName: "services" */ `@foundation/services/${id}`
                  )
        ).default;
        const controller = new ControllerClass(dependencies);
        const service = new Service({id, context, controller});

        // register the service created to find it later
        services[id] = service;

        // call create lifecycle method of the service
        await service.create();
        return service;
    });

    // register find method to find a service bu its id
    bus.serviceManager.find.register(id => services[id]);

    // listen to all events in the service manager and proxy them through the bus
    Service.listen((event, data) => bus.serviceManager[event].emit(data));

    // listen for destroyed services to remove them from the register
    Service.listen("destroyed", service => delete services[service.id]);
}
