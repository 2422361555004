export default class CapabilitiesConfigManager {
    constructor({logger}) {
        this.logger = logger;
    }

    getBoardCapabilitiesOverload({backend, board}) {
        let capabilities = {};

        try {
            // eslint-disable-next-line import/no-dynamic-require
            capabilities = require(`@savaCapabilities/boards/${backend}/${board}`);
        } catch (e) {
            const boardName = board.slice(0, board.length - 5);
            this.logger.info(`No capabilities found for backend ${backend} and board ${boardName}.`);
        }

        return capabilities;
    }
}
