import {getObjectAndMethod, REGISTRY_PROPERTY, CALL_PROPERTY, LOGGER_PROPERTY} from "./busHelper";

const getDefaultObject = registry => {
    // default structure
    const defaultObject = {
        methods: {},
        listeners: new Set(),
        events: {},
    };

    // create an emitter of events for this object, created here to avoid to much creation of function
    // while calling methods on object (we compute it once and it is done for all)
    defaultObject.emitter = (...args) => {
        for (const listener of defaultObject.listeners) {
            try {
                listener(...args);
            } catch (error) {
                registry[LOGGER_PROPERTY].error(error);
            }
        }
    };
    return defaultObject;
};

// get an object from regitry or create it with default structure if needed
const getOrCreateObject = (registry, path) => {
    const localRegistry = registry[REGISTRY_PROPERTY];
    localRegistry[path] = localRegistry[path] || getDefaultObject(registry);
    return localRegistry[path];
};

// class to encapsulate registry manipulation
export class Registry {
    constructor({logger}) {
        this[REGISTRY_PROPERTY] = {};
        this[LOGGER_PROPERTY] = logger;
    }

    listen(object, objectPath, args) {
        // get object definition
        const definition = getOrCreateObject(this, objectPath);

        // register the listener for event (the first arguemnt of call is the callback by definition)
        definition.listeners.add(args[0]);
    }

    unlisten(object, objectPath, args) {
        // get object definition
        const definition = getOrCreateObject(this, objectPath);

        // register the listener for event (the first arguemnt of call is the callback by definition)
        definition.listeners.delete(args[0]);
    }

    register(object, objectPath, args) {
        // retrieve real object
        const {objectPath: realObjectPath, method} = getObjectAndMethod(object);

        // get object definition
        const definition = getOrCreateObject(this, realObjectPath);

        // add the method into the object
        definition.methods[method] = {
            caller: args[0],
            schema: args[1],
        };
    }

    unregister(object) {
        // retrieve real object
        const {objectPath: realObjectPath, method} = getObjectAndMethod(object);

        // get object definition
        const definition = getOrCreateObject(this, realObjectPath);

        // add the method into the object
        delete definition.methods[method];
    }

    emit(object, objectPath, args) {
        // get object definition
        const definition = getOrCreateObject(this, objectPath);

        // emit the event
        definition.emitter(...args);
    }

    [CALL_PROPERTY](objectPath, method, args) {
        // get object definition
        const definition = getOrCreateObject(this, objectPath);

        // get the method and call it with good context
        const obj = definition.methods[method];
        if (!obj) {
            throw Error(`Cannot find method ${method} in object ${objectPath} of the bus`);
        }
        const {caller} = obj;
        return caller.call({emit: definition.emitter}, ...args);
    }
}
