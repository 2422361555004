import querystring from "querystring";
import url from "url";

function storeAuthCode(redirections) {
    const {code, state} = querystring.parse(redirections.url.hash?.substring(1));
    const key = `SAVA.auth.state.${state}`;
    if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
        redirections.store("authCode", code);
        const newUrl = {...redirections.url, hash: undefined};
        window.history.replaceState(window.history.state, null, url.format(newUrl));
    } else {
        throw Error("Trying to redirect from avs-iam-auth-code with state not registered");
    }
}

function storeAmazonAuthCode(redirections) {
    const {code, state, error, error_description: errorDescription} = redirections.url.query;
    if (error) {
        throw Error(`Error on redirection from Amazon: ${error}\n${errorDescription}`);
    }

    // store auth code
    redirections.store("authCode", code);

    // replace the actual url with the one from the state
    const key = `SAVA.auth.state.${state}`;
    const {url, authCode} = JSON.parse(localStorage.getItem(key));
    localStorage.removeItem(key);
    redirections.store("IAMAuthCode", authCode);
    window.history.replaceState(window.history.state, null, url);
}

export default [
    {
        id: "iam-login",
        matches: "iam-login",
    },
    {
        id: "avs-auth-code",
        matches: "avs-auth-code",
        hooks: [storeAmazonAuthCode],
    },
    {
        id: "avs-iam-auth-code",
        matches: "avs-iam-auth-code",
        hooks: [storeAuthCode],
    },
];
